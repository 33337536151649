@import "variable";
@import "rem";

$crtInputBg: var(--tap-oat-input-bg);
$crtInputColor: var(--tap-oat-input-color);
$crtIconColor: var(--tap-oat-icon-color);
$crtPlaceholder: var(--tap-oat-placeholder-color);

$formBtnHeight: 35px;
$formBtnMinWidth: 115px;

@mixin crt-form-control-wrapper {
	background-color: $crtInputBg !important;
}

@mixin crt-form-control-icon {
	color: $crtIconColor !important;
}

@mixin crt-form-control {
	background-color: $crtInputBg;
	border-radius: 0px;
	// border: none;
	border-color: transparent;
	height: 30px;
	color: $crtInputColor;
	box-shadow: none;

	&:focus {
		background-color: $crtInputBg;
		box-shadow: none;
		outline: none;
	}

	&:disabled,
	&--disabled {
		background-color: transparent !important;
		padding-left: 0px;
		padding-right: 0px;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

@mixin crt-form-control-placeholder {
	&::placeholder {
		color: $crtInputColor;
		opacity: 0.5;
	}
}

input,
textarea,
select {
	&.crt-form-control {
		@include crt-form-control;
		@include crt-form-control-placeholder;
	}

	&[color="transparent"] {
		background-color: transparent !important;
	}

	> option {
		background-color: #ffffff;
		color: $crtInputColor;
	}

	&.select-placeholder {
		color: $crtPlaceholder;
	}

	&:disabled {
		pointer-events: none;
	}
}

textarea.crt-form-control {
	height: auto;
}

.crt-custom-dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border-radius: 0px;

	select {
		font-size: inherit;
		padding-right: 2.5em;
		margin: 0;
		text-indent: 0.01px;
		// border: none;
		border-color: transparent;
		border-radius: 0px;
	}

	&::after {
		content: "";
		position: absolute;
		pointer-events: none;
	}

	&::after {
		content: "expand_more";
		font-family: "Material Icons";

		font-size: 16px;
		height: 1em;
		line-height: 1;
		right: 0.35em;
		top: 50%;
		margin-top: -0.5em;
	}

	&--hover {
		&::after {
			content: "";
		}

		&:hover {
			&::after {
				content: "expand_more";
				font-family: "Material Icons";
			}
		}
	}
}

app-date-input,
app-address-input,
app-search-control,
app-client-search-control {
	&.crt-form-control-field {
		> div,
		> app-search-control > div {
			@include crt-form-control-wrapper;
			border-radius: 0 !important;
		}

		input {
			background-color: $crtInputBg !important;
			@include crt-form-control;
			@include crt-form-control-placeholder;

			&:disabled {
				padding: 0.25rem 0.75rem !important;
			}
		}

		&:not(app-address-input) input:disabled + button {
			display: none;
		}

		button {
			padding-right: 0.25rem !important;
			& > i {
				@include crt-form-control-icon;
			}
		}

		&[disabled]:not([disabled="false"]),
		&[disabled="true"] {
			& > div,
			& > app-search-control > div {
				background-color: transparent !important;
			}
		}
	}
}

.dollar-icon {
	input.crt-form-control {
		&:disabled {
			padding-left: 12px;
			& + i {
				margin-left: 0px;
			}
		}
	}

	&-right {
		input.crt-form-control {
			&:disabled {
				padding-left: 0px;
				padding-right: 12px;
				& + i {
					margin-right: 0px;
				}
			}
		}
	}
}

app-search-control.crt-form-control-field > div button {
	padding-right: 0 !important;
}

app-chips {
	&.crt-form-control-field {
		height: auto;
		min-height: 30px;
		.chips-control__container {
			@include crt-form-control;
			@include crt-form-control-wrapper;
			height: auto !important;
			min-height: 30px !important;
		}

		&[disabled]:not([disabled="false"]),
		&[disabled="true"] {
			& > .chips-control__container {
				background-color: transparent !important;
				padding-left: 0px !important;

				& > .chips {
					background-color: transparent;
					margin-left: 0px !important;
					padding-left: 0px !important;
					padding-right: 0px !important;

					&:not(.last-chip)::after {
						content: ", ";
					}

					span {
						color: $crtInputColor !important;
					}
				}
			}
		}
	}
}

/**
Radio button with check icon
Example:
<div class="custom-control custom-radio check">
  <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
  <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
</div>
*/
.custom-radio.check {
	&.ng-invalid {
		.custom-control-label {
			&:before {
				border-color: #dc3545 !important;
			}
		}
	}
	.custom-control-input:checked ~ .custom-control-label::after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
	}
}

/**
Radio button rectangular button without icon
Example:
<div class="custom-control custom-radio box">
  <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input check">
  <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
</div>
*/
.custom-radio.box {
	padding-left: 0px;
	margin-right: 0px;
	min-height: auto;

	.custom-control-label {
		padding: 0.25px 4.57px;
		font-size: 9px;
		line-height: 19.5px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			&.gray {
				background-color: #f0f4f6;
			}
			&.green {
				background-color: #4bc025;
				color: #ffffff;
			}
			&.orange {
				background-color: #ff9f00;
				color: #ffffff;
			}
			&.red {
				background-color: #e60000;
				color: #ffffff;
			}
		}

		&::before,
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border-radius: unset;
			opacity: 0;
		}
	}

	.custom-control-input {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: unset;
		opacity: 0;

		&:checked ~ .custom-control-label {
			font-weight: 600;

			&.gray {
				background-color: #f0f4f6;
			}
			&.green {
				background-color: #4bc025;
				color: #ffffff;
			}
			&.orange {
				background-color: #ff9f00;
				color: #ffffff;
			}
			&.red {
				background-color: #e60000;
				color: #ffffff;
			}
		}
	}
}

/* extends form-control class with new design version */
$ui-gray: #ecf1f4;
$ui-btn-bg-gray: #f9f9f9;
$ui-btn-bg-hover: #dce5ec;
$ui-btn-border-gray: #e0e7ee;
$ui-tbl-gray: #cecfce;
$ui-tbl-odd: #fafafa;
$ui-tbl-even: #fefffe;
$ui-disable-bg: #e9ecef;

@mixin ui-form-control-input {
	padding: 15px;
	border: 2px solid $ui-gray;
}
@mixin ui-btn {
	width: 100%;
	color: #000;
	padding: 5px;
	border-radius: 2px;
}
input.ui-form-control {
	@include ui-form-control-input;
	&[type="checkbox"] {
		height: 16px;
		width: 16px;
	}
}
textarea.ui-form-control {
	border: 2px solid $ui-gray;
	padding: 6px;
	border-radius: 4px;
	font-size: 12px;
	&:focus,
	&:focus-visible {
		border-color: $ui-gray !important;
		outline-style: none;
		-webkit-box-shadow: 0 0 0 0.1rem rgba(87, 89, 90, 0.25);
	}
	&:disabled {
		background-color: $ui-disable-bg;
	}
}
select.ui-form-control {
	border: 2px solid $ui-gray;
	min-height: 34px;
	padding: 3px 8px;
	border-radius: 4px;
}
button.ui-btn-primary {
	@include ui-btn;
	background-color: var(--tap-bg-primary, #00263e);
	border: 1px solid var(--tap-bg-primary, #00263e);
	color: #fff;
}
button.ui-btn-gray {
	@include ui-btn;
	background-color: $ui-btn-bg-gray !important;
	border: 1px solid $ui-btn-border-gray;
	width: unset;
	font-size: 13px;
	&:hover {
		background-color: $ui-btn-border-gray !important;
		color: #000;
	}
}
button.ui-btn-white {
	@include ui-btn;
	background-color: #fff !important;
	border: 1px solid $ui-btn-border-gray;
	width: unset;
	font-size: 13px;
	&:hover {
		background-color: $ui-btn-border-gray !important;
		color: #000;
	}
}
table.ui-tbl.table-striped {
	tbody {
		border-color: unset;
		td {
			vertical-align: middle;
		}
		tr:nth-of-type(odd) {
			background-color: $ui-tbl-odd;
		}
		tr:nth-of-type(even) {
			background-color: $ui-tbl-even;
		}
	}
}
app-date-input.ui-form-control {
	input {
		@include ui-form-control-input;
	}
	.material-icons {
		font-size: 22px;
		color: #cfcfcf;
	}
}
app-input-chips.ui-form-control {
	.ng-select-multiple {
		border: 2px solid $ui-gray;
		padding: 1px!important;
		.ng-select-container {
			border: none;
		}
	}
}
app-input-chips.ui-form-control-group {
	.ng-select.ng-select-disabled > .ng-select-container {
		background-color: $ui-disable-bg;
		border: 1px solid #d9e3ed;
	}
}
