@import 'variable';

.tap-tabset {
  > .nav-tabs {
    border-bottom: none;

    .nav-item {
      position: relative;
      opacity: 1 !important;

      .nav-link {
        position: relative;

        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;

        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;

        height: 36px;
        background-color: #f3f4f6;
        border: none;
        border-top-left-radius: 0;
        border-top-right-radius: 1rem;
        // border-top-right-radius: 0.25rem;
        color: #686868;
        font-size: var(--tap-h3);
        font-weight: 600;
        letter-spacing: 0.43px;
        padding: 0.5rem 0.75rem 0.5rem 1rem;
        box-shadow: 2px 0 4px -4px black;

        opacity: 1 !important;
        &.active {
          background-color: var(--tap-primary-color);
          color: var(--tap-h1-color, #fff);
        }
      }

      &:not(:first-child) {
        .nav-link {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            margin-left: -15px;
            background-color: #f3f4f6;
            width: 50px;
            height: 20px;
            z-index: -1;
          }

          &.active::after {
            background-color: var(--tap-primary-color);
          }
        }
      }
    }
  }

  &--border-radius-left-right {
    > .nav-tabs {
      .nav-item {
        .nav-link {
          // border-top-left-radius: 1rem;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &--right-nav {
    > .nav-tabs {
      justify-content: flex-end;
    }
  }

  > .tab-content {
    // box-shadow: 2px 0 6px -4px black;
    box-shadow: 0px -4px 8px -10px #000000;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    > tab {
      margin-left: 0;
      margin-right: 0;
    }
  }

  /**
  <tabset #sidebarNav class="tap-tabset-sidebar tap-tabset-sidebar--hidden-header" [vertical]="true">
    <tab id="sidebar_nav">
      <ng-template tabHeading>
        <span>Sidebar Nav</span><br>
        <span class="font-italic">"Primary"</span>
        <i class="material-icons arrow-right md-20 d-flex align-items-center">chevron_right</i>
      </ng-template>
      <!-- Content here... -->
    </tab>
  </tabset>
  */
  &-sidebar {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;

    > .nav-tabs {
      -ms-flex: 0 0 auto;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: 210px;
      max-width: 100%;
      border-bottom: 0;

      // Adding border left color in tabset for sidebar
      // code attribute: customClass="nav-item--*{success/warning/danger}"
      .nav-item {
        margin-bottom: 5px;
        .nav-link {
          border-left: 7px solid var(--tap-crm-tab-grey-color);
        }

        &--success .nav-link {
          border-left: 7px solid var(--tap-crm-tab-green-color);
        }

        &--warning .nav-link {
          border-left: 7px solid #f8de13;
        }

        &--danger .nav-link {
          border-left: 7px solid var(--tap-crm-tab-red-color);
        }

        &--dark .nav-link {
          border-left: 7px solid var(--tap-crm-tab-black-color);
        }

        &--blue .nav-link {
          border-left: 7px solid #0052cc;
        }

        &--orange .nav-link {
          border-left: 7px solid orange;
        }
      }

      .nav-link {
        position: relative;
        border: 0;
        border-top: 1px solid #d5dee1;
        border-bottom: 1px solid #d5dee1;
        border-right: 1px solid #d5dee1;
        border-radius: 00.5rem;
        font-size: 12px;
        letter-spacing: 0.32px;
        min-height: 65px;

        .arrow-right {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }

        &.active {
          background-color: #f1f5f7;

          .arrow-right {
            font-weight: 600;
          }
        }
      }
    }

    &--hidden-header {
      .nav-tabs {
        display: none;
      }
    }

    > .tab-content {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
      // box-shadow: 2px 0 6px -4px black;
      // padding-left: 1rem;
      // padding-right: 1rem;

      > tab {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &-sidebar-crt {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;

    > .nav-tabs {
      -ms-flex: 0 0 auto;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: 210px;
      max-width: 100%;
      border-bottom: 0;

      // Adding border left color in tabset for sidebar
      // code attribute: customClass="nav-item--*{success/warning/danger}"
      .nav-item {
        margin-bottom: 5px;
        .nav-link {
          border-right: 7px solid #d5dee1;
        }

        &--success .nav-link {
          border-right: 7px solid #6dbe46;
        }

        &--warning .nav-link {
          border-right: 7px solid #f8de13;
        }

        &--danger .nav-link {
          border-right: 7px solid #ec2929;
        }

        &--dark .nav-link {
          border-right: 7px solid #212529;
        }

        &--blue .nav-link {
          border-right: 7px solid #0052cc;
        }
      }

      .nav-link {
        position: relative;
        border: 0;
        border-top: 0.5px solid #d5dee1;
        border-bottom: 0.5px solid #d5dee1;
        border-left: 0.5px solid #d5dee1;
        // border-radius: 00.5rem;
        font-size: 12px;
        letter-spacing: 0.32px;
        min-height: 43px;
        width: 225px;

        .arrow-right {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }

        &.active {
          background-color: #f1f5f7;

          .arrow-right {
            font-weight: 600;
          }
        }
      }
    }

    &--hidden-header {
      .nav-tabs {
        display: none;
      }
    }

    > .tab-content {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
      padding-left: 30px;
      // padding-right: 30px;

      > tab {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

/**
* Loop to make z-index modifier. Used for showing shadow.
* 1 is the highest z-index. Makes it easier to imagine.
* Change if it isn't.
*/
$tabs: 10;
$count: 0;

@while $count < $tabs {
  .tap-tabset .nav-item:nth-child(#{$count + 1}) .nav-link {
    z-index: $tabs - $count;
  }
  $count: $count + 1;
}
