// Custom max-width
@media (max-width: 1469px) {
	app-crm {
		.breadcrumb-label {
			font-size: 18px !important;
		}
	}

	app-loat,
	app-client-review-template {
		// height: auto !important;
		overflow: auto;

		.crt__main-content {
			width: auto !important;
		}
	}
}

// Custom max-width
@media (max-width: 1400px) {
	app-crm {
		.breadcrumb-label {
			font-size: 16px !important;
		}
	}

	app-loat,
	app-client-review-template {
		// height: auto !important;
		overflow: auto;

		.crt__main-content {
			width: auto !important;
		}
	}
}

/** Bootstrap */
// XL
@media (max-width: 1200px) {
	.tap-btn--xl--block {
		display: block;
		width: 100%;
	}

	//main,
	app-dashboard,
	.tap-search-page {
		min-height: calc(100vh - 106px) !important;
	}

	.tap-client-profile,
	.tap-business-profile {
		&__main-content,
		&__activity-timeline {
			height: calc(100vh - $tap-header-height) !important;
		}
		&__activity-timeline {
			position: absolute !important;
			top: 0px;
			right: 0px;
		}
		.tap-btn-collapse-expand {
			margin-right: 404px !important;
			border-radius: 4px !important;
			border-top-right-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
			border-right-color: transparent !important;
		}
		&--collapsed {
			.tap-btn-collapse-expand {
				margin-right: 4px !important;
				border-radius: 4px !important;
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				border-right-color: transparent !important;
			}
		}
	}

	//app-loat,
	app-client-review-template {
		height: calc(100vh - 106px) !important;
		overflow: auto;

		.crt__main-content {
			width: auto !important;
		}
	}


}

// LG
@media (max-width: 992px) {
	.tap-btn--lg--block {
		display: block;
		width: 100%;
	}

	//main,
	app-dashboard,
	.tap-search-page {
		min-height: calc(100vh - 162px) !important;
	}

	.tap-client-profile,
	.tap-business-profile {
		// &__main-content,
		// &__activity-timeline {
		//   height: calc(100vh - 162px) !important;
		// }
		.tap-btn-collapse-expand {
			right: unset !important;
			left: 0 !important;
			// margin-top: 0px !important;
			margin-left: -8px !important;
			// border-radius: 50rem !important;
			// border: 1px solid #a5a5a5 !important;
		}
		&--collapsed {
			.tap-btn-collapse-expand {
				right: 0 !important;
				left: unset !important;
				margin-top: 35px !important;
				margin-right: 4px !important;
				border-radius: 4px !important;
				// border-top-right-radius: 0px !important;
				// border-bottom-right-radius: 0px !important;
				// border-right: transparent !important;
			}
		}
	}

	app-client-profile-add,
	app-client-profile-edit,
	app-business-profile-add,
	app-business-profile-edit {
		.tap-tabset .nav-item {
			display: block;
			width: 100%;
		}
	}

	app-loat,
	app-client-review-template {
		.crt__left-sidebar {
			// width: 100% !important;
			position: absolute !important;
			width: 23px !important;
			height: calc(100% - 60px) !important;

			&.open {
				width: 225px !important;
			}

			.btn-workflow {
				height: 50px !important;
				border: unset !important;
				border-radius: unset;
				font-size: 18px !important;
				padding-top: 0.15rem !important;
			}

			.workflow-font {
				padding-top: 0.25rem !important;
			}

			.btn-font-size {
				font-size: 12px !important;
			}
		}

		.crt__main-content {
			margin-left: 35px !important;
			margin-right: 35px !important;
			width: auto !important;

			.header-font {
				height: unset !important;
				line-height: unset !important;
				padding: 0px;
				padding-left: 0px;
				padding-right: 0px;
				padding-top: 18px;
			}

			.btn-disclosure {
				height: 51px;
				background-color: #ffffff;
				margin-bottom: 20px;
			}

			.crt-signature {
				padding-left: 0px !important;
				padding-right: 0px !important;

				.btn-signature {
					height: 44px;
					background-color: #006bc6;
					color: #e8eff7;
				}
			}
		}

		.crt__right-sidebar {
			width: 100% !important;
			padding-left: 14px;
			padding-right: 14px !important;
			text-align: center;

			.btn-progress {
				height: 40px;
				font-size: 14px;
			}

			.pt-13rem {
				padding-top: 1rem !important;
			}
		}

		.crt-collapse-panel {
			width: 0px !important;

			&--open {
				width: 97% !important;
			}
		}
	}

	// app-crt-mortgage,
	// app-crt-kiwisaver {
	// 	app-main-page > .crt-main-page {
	// 		height: calc(100vh - 106px) !important;
	// 		overflow: auto;

	// 		.crt-main-content {
	// 			width: auto !important;
	// 		}
	// 	}
	// }

	app-crt-mortgage,
	app-crt-kiwisaver {
		app-main-page > .crt-main-page {
			height: calc(100vh - 106px) !important;
			overflow: auto;
		}

		.crt-main-page {
			&__sidebar {
				width: 23px !important;

				&__header {
					width: 23px !important;
					background-color: #ffffff;
					box-shadow: 0 0 7px -5px #000;

					> div {
						visibility: hidden;
					}
				}

				> accordion {
					width: 23px;
				}

				accordion-group {
					display: none !important;
				}

				&__toggle {
					display: flex !important;
					margin-right: -16px;
				}
			}

			&.open {
				.crt-main-page__sidebar {
					&__header {
						position: absolute;
						top: 0;
						left: 0;
						width: 225px !important;
						background-color: #ffffff;

						> div {
							visibility: visible;
						}
					}

					> accordion {
						position: absolute;
						top: 0;
						left: 0;
						margin-top: 56px;
						width: 225px;

						&-group {
							display: block !important;
						}
					}

					accordion-group {
						display: block !important;
					}

					&__toggle {
						margin-right: -218px;
					}
				}
			}
		}
	}
}

// MD
@media (max-width: 768px) {
	//main,
	app-dashboard,
	.tap-search-page {
		min-height: calc(100vh - 222px) !important;
	}

	.tap-btn--md--block {
		display: block;
		width: 100%;
	}

	// Widgets
	app-avs {
		app-bipolar-bar-chart {
			display: block;
			min-width: 500px;
		}
	}

	app-lrct {
		.lrct__content__pills {
			font-size: 12px !important;
		}
		.lrct__content__info {
			font-size: 10px;
		}
	}

	.tap-tabset .nav-item {
		display: block;
		width: 100%;
	}

	app-client-profile-add,
	app-client-profile-edit,
	app-business-profile-add,
	app-business-profile-edit,
	app-link-document {
		.custom-sidebar-nav {
			width: 100% !important;
		}

		.tap-tabset-sidebar {
			flex-wrap: wrap;
			> .nav-tabs {
				width: 100% !important;
			}
		}

		.label-padding-bottom-md {
			padding-bottom: 0.25rem !important;
			padding-top: 0.75rem !important;
		}
	}

	.tap-client-profile,
	.tap-business-profile {
		&__main-content,
		&__activity-timeline {
			height: calc(100vh - $tap-header-height) !important;
		}
	}

	app-loat,
	app-client-review-template {
		height: calc(100vh - 222px) !important;
		overflow: auto;

		.crt__left-sidebar {
			// width: 100% !important;
			position: absolute;
			width: 23px;

			&.open {
				width: 225px !important;
			}

			.btn-workflow {
				height: 50px !important;
				border: unset !important;
				border-radius: unset;
				font-size: 18px !important;
				// color: #ffffff !important;
				// background-color: #212629;
				padding-top: 0.15rem !important;
			}

			.btn-font-size {
				font-size: 12px !important;
			}
		}

		.crt__main-content {
			margin-left: 35px !important;
			margin-right: 35px !important;
			// background-color: unset !important;
			// padding-left: 14px;
			// padding-right: 14px;
			width: auto !important;

			.header-font {
				height: unset !important;
				line-height: unset !important;
				padding: 0px;
				padding-left: 0px;
				padding-right: 0px;
				padding-top: 18px;
			}

			.btn-disclosure {
				height: 51px;
				background-color: #ffffff;
				margin-bottom: 20px;
			}

			.crt-signature {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}

		.crt__right-sidebar {
			width: 100% !important;
			padding-left: 14px;
			padding-right: 14px;
			text-align: center;

			.btn-progress {
				height: 40px;
				font-size: 14px;
			}

			.pt-13rem {
				padding-top: 1rem !important;
			}
		}

		.crt-collapse-panel {
			width: 0px !important;

			&--open {
				width: 97% !important;
			}
		}
	}

	app-crt-mortgage,
	app-crt-kiwisaver {
		app-main-page > .crt-main-page {
			height: calc(100vh - 222px) !important;
			overflow: auto;
		}
	}
}

// SM
@media (max-width: 576px) {
	.tap-btn--sm--block {
		display: block;
		width: 100%;
	}

	.app-user-name {
		font-size: 12px;
	}

	.modal-dialog {
		width: calc(100vw - 15px) !important;
	}

	.activity-timeline__container-flow__list__item__footer {
		font-size: 10px;
	}

	app-loat,
	app-client-review-template {
		overflow: auto;

		.crt__left-sidebar {
			// width: 100% !important;
			position: absolute;
			width: 23px;

			.btn-workflow {
				height: 50px !important;
				border: unset !important;
				border-radius: unset;
				font-size: 18px !important;
				// color: #ffffff !important;
				// background-color: #212629;
				padding-top: 0.15rem !important;
			}

			.btn-font-size {
				font-size: 12px !important;
			}

			.panel {
				.btn {
					height: 50px;
				}
			}
		}

		.crt__main-content {
			margin-left: 35px !important;
			margin-right: 35px !important;
			// background-color: unset !important;
			// padding-left: 14px;
			// padding-right: 14px;
			width: auto !important;

			.header-font {
				height: unset !important;
				line-height: unset !important;
				padding: 0px;
				padding-top: 18px;
				padding-left: 0px;
				padding-right: 0px;
			}

			.btn-disclosure {
				height: 51px;
				background-color: #ffffff;
				margin-bottom: 20px;
			}

			.crt-signature {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}

		.crt__right-sidebar {
			width: 100% !important;
			padding-left: 14px;
			padding-right: 14px;
			text-align: center;

			.btn-progress {
				height: 40px;
				font-size: 14px;
			}

			.pt-13rem {
				padding-top: 1rem !important;
			}
		}

		.crt-collapse-panel {
			width: 0px !important;

			&--open {
				width: 97% !important;
			}
		}
	}
}

// TAP1-3694
// fix smaller screen white space issue on LOAT

// @media screen and (max-width: 768px) {

// 	body:has(app-loat):has(app-intro),
// 	body:has(app-loat):has(app-disclosure),
// 	body:has(app-loat):has(app-scope-of-services) {
// 		height: calc(100vh + 66px) !important;
// 	}

@media screen and (max-width: 768px) {

	body:has(app-loat) {
		height: calc(100vh + $tap-header-height) !important;
		overflow-x: hidden;
	}
	main:has(app-loat)
	 {
		height: 100% !important;
	}
	app-loat {
		height: 100% !important;
	}
}
