/*-------------------------------*\
            Muuri Grid
\*-------------------------------*/

.grid.widget-grid {
  position: relative;
  margin: 0 -0.8rem;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.item.widget-item {
  display: block;
  position: absolute;
  width: 400px;
  height: 500px;
  max-width: #{"calc(100% - 32px)"};
  margin: 15px;
  background-color: var(--tap-widget-bg);
  z-index: 1;
  color: var(--tap-h5-color);
  box-shadow: 0 0 4px #bbb;
  // resize: both;
  overflow: hidden;
  transition: opacity 0.3s ease-out;
  transition: background-color 0.3s ease 0s;

  >.ui-resizable-se {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;

    &.ui-icon {
      background-image: none !important;
      transform: rotate(0deg) !important;

      &::before {
        // Pentagon
        content: "";
        position: absolute;
        bottom: -10px;
        right: -14px;
        border-top: 5px solid var(--tap-light1-color);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        height: 0;
        width: 40px;
        transform: rotate(315deg) translate(10px, -13px);
      }

      &::after {
        // Triangle
        content: "";
        position: absolute;
        bottom: -10px;
        right: -11px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 6px solid var(--tap-light1-color);
        transform: rotate(315deg) translate(2px, -10px);
      }
    }
  }
}

.item.muuri-item-dragging {
  z-index: 3;
}

.item.muuri-item-releasing {
  z-index: 2;
}

.item.muuri-item-hidden {
  z-index: 0;
}

.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.item.muuri-item-placeholder {
  border: 1px dashed #bbb;
  z-index: 2;
  margin: 0;
  opacity: 0.3 !important;
}


[data-theme="dark"] .item.widget-item {
  --tap-h5-color: var(--tap-dark3-color);
  color: var(--tap-h5-color);

  >.ui-resizable-se.ui-icon::before {
    border-top: 5px solid var(--tap-dark4-color);
  }

  >.ui-resizable-se.ui-icon::after {
    border-top: 6px solid var(--tap-dark4-color);
  }
}