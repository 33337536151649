/*----------------------------------------------------*\
                    Default Variables
\*----------------------------------------------------*/
:root {
	--tap-bg-color: var(--tap-bg-light-color);
	--tap-text-color: #212529;

	--tap-font: "Open Sans", sans-serif;

	--tap-sm-min: 567px;
	--tap-md-min: 768px;
	--tap-lg-min: 992px;
	--tap-xl-min: 1200px;
	--tap-xxl-min: 1720px;

	--tap-max-width: 1600px;
	--header-height: 60px;

	--icon-btn-width: 32px;
	--icon-btn-height: 32px;
	--tap-normal-font-size: 13px;
	--tap-base-color: #000;
	--line-color-1: #cecece;
	--border-color-1: #dbdbdb;

	--tap-h1: 40px;
	--tap-h2: 24px;
	--tap-h3: 16px;
	--tap-h4: 15px;
	--tap-h5: 12px;
	--tap-h6: 10px;

	--tap-text-link: blue;

	--tap-bg-primary: var(--tap-primary-color);
	--tap-bg-secondary: var(--tap-secondary-color);
	--tap-bg-tertiary: var(--tap-tertiary-color);

	--tap-text-primary: var(--tap-primary-color);
	--tap-text-secondary: var(--tap-secondary-color);
	--tap-text-tertiary: var(--tap-tertiary-color);

	--tap-header-navbar-bg: var(--tap-primary-color);

	// Widgets
	--tap-widget-bg: var(--tap-light3-color);

	// Search Page
	--tap-search-bg: var(--tap-light3-color);
	--tap-search-input-color: var(--tap-h5-color);
	--tap-search-label-color: var(--tap-h5-color);

	// Datatable
	--tap-datatable-text-header-handle: var(--tap-light3-color);
	--tap-datatable-bg-row: var(--tap-light3-color);
	--tap-datatable-bg-column-setting: var(--tap-light3-color);

	// SOA
	--tap-soa-heading: var(--tap-soa-heading-color);

	// Pipelines widget
	/** Background color for inactive tab */
	--p-bg-color--inactive: var(--tap-light1-color);
	/** Font color for inactive tab.  */
	--p-font-color--inactive: var(--tap-h3-color);
	/** Background color for active tab. */
	--p-bg-color--active: var(--tap-primary-color);
	/** Font color for active tab. */
	--p-font-color--active: var(--tap-light3-color);
	/** Tab height for tab height and content height offset */
	--p-tab-height: 33px;
	/** bottom space for content height offset */
	--p-bottom-space: 30px;

	//Document Page
	--tap-document-panel-bg: var(--tap-light1-color);
	--tap-document-page-bg: var(--tap-search-bg);

	--tap-bl-sidenav-link-color: #000000;

	--tap-whitesmoke: #f5f5f5;
	--tap-white: #ffffff;
	--tap-webkit-text-fill-color: #495057;

	// OAT
	--tap-oat-input-bg: #f0f4f6;
	--tap-oat-input-color: #212629;
	--tap-oat-icon-color: #000000;
	--tap-oat-placeholder-color: #989D9F; // equivalent for --tap-oat-input-bg opacity 0.5

	// CRM Tabs
	--tap-crm-tab-red-color: #ec2929;
	--tap-crm-tab-black-color: #212529;
	--tap-crm-tab-green-color: #6dbe46;
	--tap-crm-tab-grey-color: #d5dee1;

}

/*------------ End of Default Variables ---------------*/

/*----------------------------------------------------*\
              Dark Theme Variables
\*----------------------------------------------------*/
[data-theme="dark"] {
	--tap-bg-color: var(--tap-dark1-color); //#00263e;
	--tap-text-color: var(--tap-dark4-color);

	// Widgets
	--tap-widget-bg: var(--tap-dark2-color);

	// Search Page
	--tap-search-bg: var(--tap-dark2-color);
	--tap-search-input-color: var(--tap-dark3-color);
	--tap-search-label-color: var(--tap-dark3-color);

	// Datatable
	--tap-datatable-text-header-handle: var(--tap-dark2-color);
	--tap-datatable-bg-row: var(--tap-dark2-color);
	--tap-datatable-bg-column-setting: var(--tap-dark2-color);

	//Document Page
	--tap-document-panel-bg: var(--tap-dark1-color);

	--tap-bl-sidenav-link-color: #ffffff;
}
/*----------- End of Dark Theme Variables -----------*/

$tap-font: "Open Sans", sans-serif;
$sm-min: 567px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xxl-min: 1720px;
$tap-max-width: 1600px;
$header-height: 60px;
$icon-btn-width: 32px;
$icon-btn-height: 32px;

$normal-font-size: 13px;

$tap-base-color: #000;

$line-color-1: #cecece;
$border-color-1: #dbdbdb;

$bg-body: #f8f9fa;

// Client Customer page > Primary form
$special-collapse-threshold: 1520px;

/** color for inactive items. used in table header somewhere. */
$inactive-color-1: #d5dee1;

$invalid-color: #dc3545;

$disabled-bg-color: #f1f5f7;

/*----------- MOAT Variables -----------*/

$color-white: #fff;
$border-color: #dee2e6;
$box-shadow: rgba(0, 0, 0, 0.1);
$box-shadow-darker: rgba(0, 0, 0, 0.54);
$color-bluish-black: #212629;
$color-gray: #a9a9a9;

/*----------- CRM Variables -----------*/

$color-whitish: #f4f4f4;
$color-grayish-white: #dedede;
$color-dark-white: #eeeeee;
$color-bluish-white: #d9e3ed;
$color-white-bluish: #e3f4f6;
$color-gray-icons: #9B9C9C;

/*----------- Business Settings Variables -----------*/

$color-grayer: #686868;
$color-dark-gray: #353535;
$color-bluer-white: #d9e3ed;
$color-form-gray: #545d70;

/*----------- Custom App Colors -----------*/

// Widgets

$widget-color-green: #2e8b57;
$widget-color-orange: #ff8408;
$widget-color-red: #ed232d;
$widget-color-blue: #0088cc;

$widget-color-white: #fff;
$widget-color-gray: #909090;
$widget-color-gray-lighter: #ccc;
$widget-color-gray-stroke: #d5dee1;

/*----------- BUSINESS SETTINGS/VIEW Variables -----------*/

$box-shadow-gray: rgba(155, 155, 155, 0.4);
$color-black-grayish: #353535;

/*----------- TAP LEVEL Variables -----------*/
$tap-primary-color: #212529;

/*-------------- V2 variables --------------*/
/*-------------- REWORK --------------*/
$tap-header-height: 60px;
$tap-header-colorbar-height: 9px;
$tap-header-tablet-height: 128px;
$tap-color-primary-hover: color-mix(in srgb, var(--tap-primary-color) 5%, white);

$tap-color-black: #29292C;
$tap-color-gray: #9C9CAA;
$tap-color-gray-light: #EFEFEF;

