@use "mixins" as m;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tap-crm-primary: mat-palette($mat-indigo);
$tap-crm-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tap-crm-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$tap-crm-theme: mat-light-theme(
	$tap-crm-primary,
	$tap-crm-accent,
	$tap-crm-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($tap-crm-theme);

/* You can add global styles to this file, and also import other style files */

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "../node_modules/jquery-ui-dist/jquery-ui.min.css";
@import "../node_modules/jquery-ui-dist/jquery-ui.structure.min.css";
@import "../node_modules/jquery-ui-dist/jquery-ui.theme.min.css";

// @import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
// @import "../node_modules/froala-editor/css/froala_style.min.css";
// @import "../node_modules/froala-editor/css/plugins.pkgd.min.css";

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "../node_modules/chartist/dist/chartist.min.css";

@import "../node_modules/bootstrap-select/dist/css/bootstrap-select.css";

@import "../node_modules/spin.js/spin.css";

// Froala
@import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../node_modules/froala-editor/css/froala_style.min.css";
@import "../node_modules/froala-editor/css/plugins.pkgd.min.css";

@import "./scss/rem";
@import "./scss/fonts";
@import "./scss/variable";
@import "./scss/material";
@import "./scss/fontawesome";

html {
	scroll-behavior: auto;
}

body {
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	font-size: 13px;
	transition: background-color 0.3s ease 0s;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**
 Add your custom dark theme here
*/
[data-theme="dark"] {
	.bg-white,
	.bg-light {
		--tap-text-color: #212529;
		color: var(--tap-text-color);
	}

	.add-remove-widget__title {
		--tap-h2-color: var(--tap-dark3-color);
		color: 1px solid var(--tap-h2-color);
	}

	.widget__title-divider {
		background-color: var(--tap-dark3-color);
	}

	.add-remove-widget__title--border-bottom {
		border-bottom: 1px solid var(--tap-dark3-color);
	}

	.add-remove-widget__label {
		--tap-h5-color: var(--tap-dark3-color);
		color: var(--tap-h5-color);
	}

	.widget__drag-handle {
		--tap-h6-color: var(--tap-dark3-color);
		color: var(--tap-h6-color);
	}

	.a-table__header-cell.tap--bg-primary {
		--tap-bg-primar: var(--tap-dark3-color);
		background-color: var(--tap-bg-primary);
		color: var(--tap-dark6-color);
	}

	.a-table__header-cell.tap--bg-inactive {
		--tap-h5-color: var(--tap-dark3-color);
		color: var(--tap-h5-color);
	}

	.p-window {
		/** Background color for inactive tab */
		--p-bg-color--inactive: var(--tap-dark4-color);
		/** Font color for inactive tab.  */
		--p-font-color--inactive: var(--tap-dark3-color);
		/** Background color for active tab. */
		--p-bg-color--active: var(--tap-dark3-color);
		/** Font color for active tab. */
		--p-font-color--active: var(--tap-dark6-color);
	}
}
/* End of custom dark theme */

/** override - could be extracted to own file

*/
@media (min-width: var(--tap-sm-min)) {
	.container {
		max-width: var(--tap-max-width);
	}
}

@media (min-width: var(--tap-md-min)) {
	.container {
		max-width: var(--tap-max-width);
	}
}

@media (min-width: var(--tap-lg-min)) {
	.container {
		max-width: var(--tap-max-width);
	}
}

@media (min-width: var(--tap-xl-min)) {
	.container {
		max-width: var(--tap-max-width);
	}
}

.tooltip:not(.dark-tooltip) {
	transform: translate3d();
	opacity: 1 !important;

	&.bs-tooltip {
		&-top > .arrow::before {
			border-top-color: #dbdbdb;
		}
		&-right > .arrow::before {
			border-right-color: #dbdbdb;
		}
		&-bottom > .arrow::before {
			border-bottom-color: #dbdbdb;
		}
		&-left > .arrow::before {
			border-left-color: #dbdbdb;
		}
	}

	> .tooltip-inner {
		max-width: 500px;
		border-color: #dbdbdb;
		border-style: solid;
		border-width: 1px;
		padding: 0.5rem;
		background-color: #ffffff;
		color: #212529;
		font-size: 13px;
		text-align: left;
	}
}
.tooltip.dark-tooltip {
	transform: translate3d();
	opacity: 1 !important;
	> .tooltip-inner {
		max-width: 500px;
		font-size: 13px;
	}
}

// all
label {
	margin-bottom: 0px;
}

.tap-container {
	max-width: $tap-max-width;
	margin: auto;
}

h1 {
	font-size: 22px;
	font-weight: 600;
	letter-spacing: 1.12px;
	color: #353535;
}

h2 {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.91px;
	margin-bottom: 0px;
}

h3 {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.91px;
}

h4 {
	font-weight: 600;
	font-size: 15px;
	letter-spacing: 0.91px;
}

h5 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.91px;
}

h6 {
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.91px;
}

p {
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.91px;
}

a,
a:hover {
	color: initial;
	text-decoration: none !important;
}

.tap-btn-link,
.tap-btn-link:focus,
.tap-btn-link:hover {
	font-size: $normal-font-size;
	text-decoration: none;
}

.icon-btn {
	width: $icon-btn-width;
	height: $icon-btn-height;
	border: none;
	background: none;
	cursor: pointer;
	padding: 1px 6px;

	@media (min-width: 992px) {
		width: 26px;
	}
}

.icon-btn-bg {
	@extend .icon-btn;
	background-color: #eee;
	border: 1px solid $line-color-1;

	&:hover {
		filter: brightness(90%);
	}

	&:active {
		filter: brightness(80%);
	}
}

/*#region GeneralStyles*/
/**
used in place of .row to use when there shouldnt be any part of the content leaking outside its borders(literal border, not style)
for example background color should not show at the left of the left most border
used to make make content look aligned.
*/
.contained-row {
	display: flex;
	flex-wrap: wrap;
}

.tap-border-bottom {
	border-bottom: 2px solid $line-color-1;
}

/*#endregion*/

//header
.crm-header {
	@media (min-width: 992px) {
		min-height: var(--header-height, 60px);
	}
}

.header-logo-container {
	height: var(--header-height, 60px);
	max-width: 200px;
}

.footer-logo-container {
	height: 30px;
	max-width: 200px;
}

.app-user-pic-container img {
	max-width: 30px;
	padding-right: 10px;
}

.app-user-name {
	font-size: 15px;
	padding-right: 10px;
	letter-spacing: 0.07rem;
}

.tap-header-username-color {
	color: var(--tap-header-username-color, #000000);
}

img.app-header-logo {
	max-height: 100%;
	max-width: 100%;
}
.h-fill {
	height: 100%;
}
.w-fill {
	width: 100%;
}
.fill {
	height: 100%;
	width: 100%;
}

.max-fill {
	max-height: 100%;
	max-width: 100%;
}

.carret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	cursor: pointer;
}

//search
.global-search {
	min-height: 49px;
}

input.search {
	background: url(./assets/images/search.png) calc(100% - 6px) / 15px no-repeat
		transparent;
	padding-right: 20px;
	font-size: 12px;
	right: 0px;
	position: relative;
	width: 100%;
}

.adjust-huener-container {
	padding-right: 10px;
	width: 45px;
	position: relative;
}

.searchResult {
	z-index: 1010;
	position: absolute;
	background-color: white;
	color: #212529;
	top: 100%;
	width: 100%;

	.search-result-main {
		max-height: 472px;
		overflow-y: scroll;
	}

	.search-result-single {
		padding: 5px 8px;

		.font-weight-500 {
			color: #353535;
			font-size: 12px;
			font-weight: 600;
			line-height: 17px;
			letter-spacing: 0.68px;
			padding-bottom: 1px;
		}

		.services {
			color: #353535;
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0.68px;
		}
	}

	a {
		background-color: #ffffff;
		&:nth-child(even) {
			background-color: #ececec;
		}
	}
}

//navigation
.text-white,
.text-white a {
	color: #fff;
}

.dropdown-item {
	font-size: 13px;
	letter-spacing: 0.73px;
}

//controls
.form-control {
	padding: 0.25rem 0.75rem;
	font-size: 12px;
	border-radius: 3px;
	border: 1px solid #f0f0f0;
	background-color: #ffffff;
	width: 100% !important;
	min-height: 27px;
	height: 27px;
	outline-style: none;
}

.form-control::-webkit-input-placeholder {
	color: #d9e3ed;
}

.form-inline label {
	line-height: 13.5px;
	color: #353535;
	font-size: 12.5px;
	font-weight: 400;
	letter-spacing: 0.3px;
}

.form-check-input {
	min-height: 27px;
}

select.form-control {
	min-height: 27px;
}

.tap-admin-table thead th {
	border-color: #4a4a4a;
	background-color: #4a4a4a;
}

//primary customer
@media (max-width: ($special-collapse-threshold - 1px)) and (min-width: var(--tap-lg-min)) {
	.top-field-container {
		flex: 0 0 25%;
		max-width: 25%;
	}
}

.name-section .form-control {
	font-weight: 600;

	&::placeholder {
		font-weight: normal;
	}
}

.sc-border {
	border-bottom: 2px solid #cecece;
	margin-left: 15px;
	margin-right: 15px;
}

.scc-border {
	border-top: 1px solid #e7e7e7;
	margin-left: 15px;
	margin-right: 15px;
}

.s-border {
	border-top: 1px solid #e7e7e7;
}

//search
.search-button {
	display: grid;
}

.clickable {
	cursor: pointer;
}

.clickable:focus {
	outline: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	box-shadow: none !important;
	border-color: transparent !important;
	background-color: #353535;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-light.focus,
.btn-light:focus,
.btn-danger.focus,
.btn-danger:focus {
	box-shadow: none !important;
	border-color: transparent !important;
}

@media (min-width: var(--tap-xxl-min)) {
	.tap-control {
		position: absolute;
		left: 100%;
		display: flex;
	}
}

@media (max-width: calc(var(--tap-xxl-min) - 0.02px)) {
	.tap-control {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.tap-control {
	button,
	a {
		width: $icon-btn-width;
		height: $icon-btn-height;
		border: none;
		background: none;
		cursor: pointer;
		padding: 1px 6px;
	}
}

.modal-header {
	color: #fff;
	font-size: 14px;
	padding: 8px;
	background: #4a4a4a;
}

.modal-body {
	p {
		margin-bottom: 5px;
	}

	select {
		min-width: 150px;
		margin: auto;
		padding: 3px;
	}

	input,
	app-date-input input {
		padding: 3px !important;
	}
}

.btn-primary {
	padding: 8px 20px;
	color: #fff;
	padding: 8px 20px;
	font-size: 12px;
	letter-spacing: 0.64px;
	border-radius: 0px;
	border: none;
	background-color: #353535;
}

.btn-noleftpadding {
	padding-left: 0px;
}

.btn-primary:hover {
	background-color: #353535;
	border-color: #353535;
}

.btn-light {
	padding: 8px 20px;
	font-size: 12px;
	letter-spacing: 0.64px;
	border-radius: 0px;
	border: none;
}

.btn-danger {
	color: #fff;
	padding: 8px 20px;
	font-size: 12px;
	letter-spacing: 0.64px;
	border-radius: 0px;
	border: none;
}

.btn-outline-secondary {
	color: #6c757d !important;
	background-color: transparent !important;
}

.btn-outline-secondary:hover {
	color: #6c757d !important;
	outline: none;
	box-shadow: none;
	background-color: transparent !important;
	border-color: transparent;
}

.btn-outline-secondary:focus {
	outline: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
	border-color: transparent;
}

.service-tabs .nav-tabs {
	border-bottom: 2px solid #cecece;

	.nav-item {
		margin-bottom: -2px;
		margin-top: 5px;
	}

	.nav-link {
		border-top: none;
		border-right: none;
		border-left: none;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0.68px;
		color: inherit;
	}

	.nav-link.active,
	.nav-link:hover,
	.nav-link:focus {
		border: 0px;
		border-bottom: 2px solid #353535;
		color: #353535;
	}
}

tag {
	font-size: 12px !important;
	margin: 0px !important;
	margin-right: 5px !important;
	height: 28px !important;
	margin-bottom: 2px !important;
}

tag > div:first-child {
	line-height: 27px !important;
	font-size: 11px !important;
	padding-left: 3px !important;
}

.service-required {
	padding-left: 19px;
}

.ng2-tag-input {
	padding: 0px !important;
	border-bottom: 1px solid #efefef !important;
}

.ng2-tag-input__text-input {
	height: 30px !important;
}

delete-icon svg {
	height: 28px !important;
	width: 12px !important;
}

.tap-notes tbody {
	border-bottom: 1px solid #dee2e6;
	font-size: 12px;
}

.table td {
	color: #353535;
}

.service-tabs.document-tabs .tab-content > .tab-pane {
	margin-left: 0px;
	margin-right: 0px;
}

.service-tabs.data-import .nav-tabs {
	border-bottom: 1px solid #cecece;

	.nav-link {
		font-size: 12.5px;
	}
}

.service-tabs.data-import .tab-content > .tab-pane {
	margin-left: 0px;
	margin-right: 0px;
}

.btn.icon-btn {
	background: none;
}

.search-name {
	padding-left: 17px;
}

.crm-email {
	padding-left: 17px;
}

.crm-home {
	padding-left: 19.5px;
}

.app-user-header {
	border-bottom: 3px #f0f0f0 solid;
}

@media (max-width: 991px) {
	.form-inline label {
		padding-top: 8px;
		padding-bottom: 3px;
	}

	.service-required,
	.search-name,
	.crm-email,
	.crm-home {
		padding-left: 15px;
	}
}

.pipeline-body:nth-child(2n),
.pipeline-body a:nth-child(2n + 1) > div {
	background-color: #fff !important;
}

.pipeline-body a {
	color: #353535;
	text-decoration: none !important;
}

.pipeline-body a.btn-link {
	background: transparent;
	-webkit-appearance: none;
}

.pipeline-body a:hover,
.pipeline-body a:focus {
	text-decoration: none !important;
}

.dropdown-menu {
	min-width: 8rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
	background-color: #e6eaed;
	outline: none;
}

.short-input {
	padding-left: 12px;
}

@media (max-width: 767px) {
	.form-inline label {
		padding-top: 10px;
		padding-bottom: 4px;
	}

	.dropdown-menu.show {
		width: 100%;
	}

	.short-input {
		padding-left: 15px;
	}

	.global-search {
		min-height: auto;
	}
}

@media (min-width: 576px) {
	.form-inline label {
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.chckbox {
	width: 17px !important;
	height: 17px !important;
}

.rdbtn {
	width: 17px !important;
	height: 17px !important;
}

.ddown {
	width: 100% !important;
	max-width: 100%;
}

.form-control:focus {
	box-shadow: 0 0 0 0.1rem rgba(97, 103, 109, 0.25) !important;
	border-color: #6c757d52;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(87, 89, 90, 0.25) !important;
}

input.chckbox.form-control:focus {
	box-shadow: none;
	border-color: transparent;
	-webkit-box-shadow: none;
}

.suggestion {
	position: absolute;
	top: 101%;
	width: 100%;
	left: 0;
	background-color: #f5f5f5;
	border: 0;
	z-index: 10;
	max-height: 300px;
	overflow-y: auto;
}

.suggestion > tr > td {
	padding: 10px;
	font-family: Arial;
	cursor: pointer;
}

.suggestion > tr > td:hover {
	color: #393b3c;
	background-color: #d8d8d8;
}

//--------------------------------------------------------------------//

select#AdviserId {
	width: 192px;
}

select {
	padding-right: 2.5em;
	font-size: inherit;
	text-indent: 0.01px;
	border: 1px solid #d9e3ed;
	border-radius: 4px;
	background-position-x: right;
	background-position-y: center;
	background-size: auto auto;
	-webkit-appearance: none;
	background-position-y: 11px;
	background-position-x: 96%;
	background-size: 9px;
}

.hoverBg a:hover {
	background-color: white !important;
	color: grey !important;
}

.hoverBg button:hover {
	background-color: white !important;
	color: grey !important;
}

.hoverBg > .ng-star-inserted button:hover {
	text-align: left;
}

button:focus {
	outline: none;
}

//Dropdown

/* Custom dropdown */
.intError {
	border: 1px solid #dc3545 !important;
}

span.tap-required {
	position: absolute;
	left: -9px;
	top: -3px;
	color: #dc3545;
	font-size: 14px;
}
span.tap-required-crt {
	position: absolute;
	left: -9px;
	top: -1px;
	color: #dc3545;
	font-size: 14px;
}
span.tap-required-field {
	position: absolute;
	left: -3px;
	top: -3px;
	color: #dc3545;
	font-size: 14px;
}

.custom-dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: inset 0 2px 13px rgba(0, 0, 0, 0.025);
}

.custom-dropdown select {
	font-size: inherit;
	padding-right: 2.5em;
	margin: 0;
	text-indent: 0.01px;
	border: 1px solid #d9e3ed;
	border-radius: 4px;
}

.maxWidth {
	width: 100%;
}

.custom-dropdown::before,
.custom-dropdown::after {
	content: "";
	position: absolute;
	pointer-events: none;
}

.custom-dropdown::after {
	/*  Custom dropdown arrow */
	content: "\25BC";
	height: 1em;
	font-size: 0.625em;
	line-height: 1;
	right: 1.2em;
	top: 50%;
	margin-top: -0.5em;
}

.custom-dropdown::before {
	/*  Custom dropdown arrow cover */
	width: 2em;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 0 3px 3px 0;
}

.custom-dropdown::after {
	color: rgba(0, 0, 0, 0.4);
}

.alt-disable {
	pointer-events: none;
}

.container-fluid.pri .custom-dropdown {
	width: 100%;
}

.container-fluid.sc-customer .custom-dropdown {
	width: 100% !important;
}

.container-fluid.pb-2.px-0 .custom-dropdown {
	width: 100% !important;
}

.divActivities .custom-dropdown {
	width: 100% !important;
}

.custom-dropdown > select > option {
	padding: 10px;
	font-family: "Open Sans", sans-serif;
	cursor: pointer;
}

.custom-dropdown > select > option:hover {
	color: #393b3c;
	background-color: #d8d8d8;
}

.search-result-container button:hover {
	background-color: #d9d9d9 !important;
	color: #212529 !important;
	border-radius: 0px !important;
}

button.btn.btn-link.ng-star-inserted.text-dark.text-left.w-100 {
	font-size: 12px;
	text-decoration: none;
}

:required:focus {
	box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
}

.paddingLeft {
	padding-left: 8px;
}

.marginLeft {
	margin-left: 2px;
	width: 99% !important;
}

.overlay {
	position: fixed;
	background: transparent;
	opacity: 0.3;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
}

.note-details {
	white-space: pre-wrap;
}

.widget-title {
	border-bottom: 2px solid #cecece;
}

//======================================  Progress Bar  ====================================
.lblTitle {
	width: 279px;
	height: 30px;
	color: #00bbe7;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.91px;
}

.lblHeader {
	width: 132px;
	height: 19px;
	color: #353535;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.73px;
	padding-left: 0 !important;
	margin-top: 15px;
}

.lblHeader2 {
	width: 132px;
	height: 19px;
	color: #353535;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.73px;
	padding-left: 0 !important;
}

.lblTotal {
	font-size: 22px;
	font-weight: 600;
	letter-spacing: 1.12px;
}

.mrgTop {
	text-align: right;
	vertical-align: bottom !important;
	font-weight: 400;
}

.borderLine {
	border: solid 1px;
	border-color: lightgrey;
}

.progress {
	border-radius: 12px;
	height: 20px;
}

bar.progress-bar {
	background-color: grey;
}

.dashboard-activity {
	position: absolute;
	right: 0px;
	top: -15px;
}

.dashboard-activity-table thead th {
	padding: 5px;
	padding-top: 0px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.68px;
}

.dashboard-activity-table tbody + tbody {
	border-top: 1px solid #dee2e6;
}

.dashboard-activity-table thead th {
	border-bottom: 1px solid #dee2e6;
}

.dashboard-activity-table td {
	padding: 5px;
	padding-top: 10px;
	padding-bottom: 8px;
	word-wrap: break-word;
	max-width: 120px;
}

.dashboard-activity-table tr td:last-child {
	max-width: 50px;
	word-wrap: break-word;
}

@media (max-width: 1650px) and (min-width: 1401px) {
	.dashboard-activity-table thead th:last-child {
		display: none;
	}
}

@media (max-width: 855px) {
	.dashboard-activity-table thead th:last-child {
		display: none;
	}
}

.dashboard-activity-main {
	max-height: 884px;
	overflow-x: auto;
	overflow-y: auto;
}

@media (max-width: 767px) {
	.scrollx {
		width: 200em !important;
		overflow-x: auto !important;
		max-width: 884px;
	}

	.tblScroll {
		max-width: 884px;
		overflow-x: scroll;
	}

	.hideScroll {
		overflow-x: hidden;
	}

	.maxWidth {
		max-width: none;
	}
}

@media (max-width: 991px) {
	.businessLvlScroll {
		overflow-x: auto !important;
		max-width: 884px;
	}
}

@media (max-width: 1182px) {
	.tblNotes {
		width: 200em !important;
		overflow-x: auto !important;
		max-width: 884px;
	}

	.minScroll {
		max-width: 884px;
		overflow-x: scroll;
	}

	.hideScroll {
		overflow-x: hidden;
	}
}

.notes-list {
	min-width: $md-min;
}

.app-form-control-focus {
	box-shadow: 0 0 0 0.1rem rgba(97, 103, 109, 0.25) !important;
	border-color: #6c757d;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(87, 89, 90, 0.25) !important;
}

.app-form-control-disabled {
	background-color: #e9ecef;
	opacity: 1;
}

.chips-dropdown {
	position: absolute;
	top: 100%;
	width: 100%;
	left: 1px;
	z-index: 1000;
}

.no-outline {
	outline: none;
}

.no-disable[disabled] {
	background-color: inherit;
}

.page-link {
	color: #353535;
}

.page-item.active .page-link {
	background-color: #353535;
	border-color: #353535;
}

.page-link:hover {
	color: #6c757d;
}

@media (min-width: 992px) {
	.physicalAddress-input {
		max-width: 81.333333%;
		margin-left: 4px;
		flex: 0 0 81.333333%;
		-webkit-box-flex: 0;
	}
}

// Multiselect Dropdown
.custom-width {
	max-height: 252px;
	font-family: Verdana;
	font-style: normal;
	overflow-y: auto;

	-webkit-overflow-scrolling: touch;
}

.custom-width button {
	font-size: 12px;
}

.chips-dropdown {
	button {
		border-radius: 0px;
	}

	> button.selected,
	&.custom-width button:hover {
		background-color: var(--tap-bg-primary) !important;
		color: var(--tap-h1-color) !important;
		text-decoration: none;
	}
}

@media (min-width: 768px) {
	.nextline {
		word-wrap: break-word;
		overflow: hidden;
		display: block;
	}

	.notesExclusion {
		margin-left: 26px;
	}
}

@media (max-width: 991px) {
	.notesExclusion {
		margin-left: 0px;
	}
}

.table-item:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.search-result-container {
	position: absolute;
	top: 100%;
	border: 1px solid #dee2e6;
	background-color: white;
	z-index: 10;
	left: -1px;
	right: -1px;
	max-height: 200px;
	overflow-y: scroll;
	overflow-x: hidden;
	vertical-align: middle;
}

/*-----------------------------------*\
        Search Page Custom Style
\*-----------------------------------*/
.tap-search-page {
	position: relative;
	background-color: var(--tap-search-bg);
	min-height: calc(100vh - #{$header-height});
	color: #686868;

	/* Header filters */
	form {
		.column-settings-icon {
			color: #686868;
		}

		&.filter-form {
			padding-bottom: 0.75rem;
		}

		label,
		search-collapse {
			color: #686868;
		}

		label {
			letter-spacing: 0.32px;
		}

		app-date-input input,
		.form-control,
		.chips-control__container {
			border-radius: 4px !important;
			background-color: #ffffff !important;
			border: 1px solid #d9e3ed !important;
			box-shadow: inset 0 2px 13px rgba(0, 0, 0, 0.025);
		}

		app-date-input input {
			color: #495057 !important;
		}

		.search-collapse .material-icons,
		.sortable-icon .material-icons {
			color: var(--tap-light4-color);
		}
	}

	/* Datatable */
	datatable-header-cell {
		letter-spacing: 0.45px !important;
	}

	datatable-body-cell {
		color: #686868 !important;

		a {
			color: #686868 !important;
			letter-spacing: 0.32px;
		}
	}
}

/**
* NGX DATATABLE BOOTSTRAP
*/

.ngx-datatable.bootstrap:not(.widget) {
	box-shadow: none;
	font-size: 12px;
	transition: height 0.5s linear;

	&.showSearchFields {
		transition: height 0.3s linear;
	}

	.datatable-header {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 32px;
			background-color: var(--tap-whitesmoke);
			border: 1px solid #d9e3ed;
		}

		.link-header {
			border: 0;
		}

		.datatable-header-cell {
			background-color: var(--tap-whitesmoke, rgba(150, 150, 150, 0.33));
			padding: 0.2rem 0.2rem 0.2rem 1.5rem;
			vertical-align: bottom;
			color: #000000;
			border-right: 0px;
			border-top: 1px solid #d9e3ed;
			border-bottom: 1px solid #d9e3ed;
			font-weight: 600;
			letter-spacing: 0.5px;
			font-size: var(--tap-h6);

			user-select: none;
			/* supported by Chrome and Opera */
			-webkit-user-select: none;
			/* Safari */
			-khtml-user-select: none;
			/* Konqueror HTML */
			-moz-user-select: none;
			/* Firefox */
			-ms-user-select: none;
			/* Internet Explorer/Edge */

			&.dragging {
				opacity: 0.8;
				z-index: -1;
			}
			border-left: 1px solid #d9e3ed;

			&:last-child {
				border-right: 1px solid #d9e3ed;
			}

			&.link-header {
				display: none;
				border-left: none;
				border-right: none;
				padding: 0 !important;
			}

			&.sort-active {
				background-color: #dadada;
			}

			.datatable-header-cell-template-wrap {
				display: flex;
				width: calc(100% - 12px);

				.datatable-header-cell-wrapper {
					flex: 1;
					display: inline-block;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			i.material-icons {
				position: absolute;
				top: 7px;
				left: 2px;
				color: #888a8e;
				font-size: 16px;
				cursor: -webkit-grab;
				cursor: -moz-grab;
				cursor: grab;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			i.svg-icon {
				position: absolute;
				top: 18%;
				right: 12px;
				cursor: move;
				user-select: none;

				path {
					fill: #464646;
				}
			}
		}

		& .dragging-state {
			& > datatable-header-cell {
				&:hover:not(.dragging) {
					opacity: 0.8;
					overflow: visible !important;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: unset;
						width: 1px;
						height: 100%;
						transform: translateX(-1px);
						border-left: 2px solid #353535;
						border-right: none;
						z-index: 12;
					}
				}

				&.dragging ~ *:hover {
					overflow: visible !important;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: unset;
						right: 0;
						width: 1px;
						height: 100%;
						transform: translateX(1px);
						border-left: none;
						border-right: 2px solid #353535;
						z-index: 12;
					}
				}
			}
			* {
				cursor: -webkit-grabbing !important;
				cursor: grabbing !important;
			}
		}

		.datatable-header-cell-label.draggable {
			display: inline-block;
			width: 90%;
		}

		.datatable-header-cell .datatable-header-cell-label {
			line-height: 24px;
		}
	}

	.datatable-body {
		background-color: #ffffff;
		overflow: unset;
		overflow-y: scroll;
		overflow-x: auto;
	}

	.datatable-body .datatable-row-wrapper {
		will-change: transform;
		&:hover {
			z-index: 12;
		}
	}

	.datatable-body .datatable-body-row {
		will-change: transform;
		vertical-align: top;

		&::before {
			content: "";
			position: sticky;
			left: 0;
			width: 0.5rem;
			height: 100%;
			background-color: #dde2e6;
			z-index: 1;
		}

		.datatable-body-cell.fixed-column:first-child {
			border-left: 0.5rem solid #dde2e6;
		}

		.datatable-body-cell.fixed-column:last-child {
			border-right: 1px solid #dde2e6;
		}

		&.isOverdue {
			&::before {
				background-color: #ed2924;
			}
			.datatable-body-cell.fixed-column:first-child {
				border-left: 0.5rem solid #ed2924;
			}
		}

		&.isDueDate {
			&::before {
				background-color: #6dbe46;
			}
			.datatable-body-cell.fixed-column:first-child {
				border-left: 0.5rem solid #6dbe46;
			}
		}

		&.isDueNextFiveDays {
			&::before {
				background-color: #ff9d00;
			}
			.datatable-body-cell.fixed-column:first-child {
				border-left: 0.5rem solid #ff9d00;
			}
		}
	}

	&.no-overdue {
		.datatable-body {
			border-left: 1px solid #d9e3ed;

			.datatable-body-row::before {
				content: "";
				position: absolute;
				left: 0;
				width: 0rem;
				height: 100%;
				background-color: #d9e3ed;
				z-index: 1;
			}
		}
	}

	.datatable-body .datatable-body-row .datatable-body-cell {
		&.link-ellipses {
			&:last-child {
				position: sticky !important;
				right: 0;
				border-right: 1px solid #d9e3ed;
				border-bottom: 1px solid #d9e3ed;
				border-left: 1px solid #d9e3ed;
				z-index: 1000;
				background-color: var(--tap-whitesmoke, #f5f5f5);
			}
		}

		&:last-child {
			border-right: 1px solid #d9e3ed;
		}
	}

	&.no-action-btn {
		.datatable-body
			.datatable-body-row
			.datatable-row-center
			.datatable-body-cell {
			&:last-child {
				border-right: 1px solid #d9e3ed;
				background-color: #ffffff;
			}
		}
	}

	.datatable-body .datatable-body-row .datatable-row-right {
		background: #ffffff;
		box-shadow: 6px 9px 38px 1px #dce2e4;
	}

	.datatable-body .datatable-body-row.active {
		background-color: #1483ff;
		color: #fff;
	}

	.datatable-body .datatable-body-row .datatable-body-cell {
		text-align: left;
		vertical-align: top;
		overflow: visible;
		border-left: 1px solid #d9e3ed;
		border-bottom: 1px solid #d9e3ed;
		border-right: 1px solid transparent;

		&.fixed-column {
			background-color: #f0f3f5 !important;
		}

		&.fixed-column-border {
			border: 1px solid #d9e3ed !important;
		}

		&.link-ellipses {
			background-color: #e3f4f6;
		}
	}

	.datatable-body .datatable-body-row:hover {
		background-color: var(--tap-whitesmoke, #f5f5f5);
	}

	.datatable-body
		.datatable-body-row
		.datatable-row-right
		.datatable-body-cell:last-child {
		border-right: 1px solid #d9e3ed;
		border-left: 1px solid #d9e3ed;
		border-bottom: 1px solid #d9e3ed;

		.datatable-body-cell-label {
			top: 6px;
			position: relative;
		}
	}

	.datatable-body .empty-row {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 0;
	}

	.datatable-footer {
		color: #424242;
	}

	.datatable-footer .page-count {
		line-height: 50px;
		height: 50px;
		padding: 0 1.2rem;
	}

	.datatable-footer .datatable-pager {
		margin: 0 10px;
		vertical-align: top;
	}

	.datatable-footer .datatable-pager ul li {
		margin: 10px 0px;
	}

	.datatable-footer .datatable-pager ul li:not(.disabled).active a,
	.datatable-footer .datatable-pager ul li:not(.disabled):hover a {
		background-color: #545454;
		font-weight: 600;
		color: #fff;
	}

	.datatable-footer .datatable-pager a {
		height: 22px;
		min-width: 24px;
		line-height: 22px;
		padding: 0;
		border-radius: 3px;
		margin: 0 3px;
		text-align: center;
		vertical-align: top;
		text-decoration: none;
		vertical-align: bottom;
		color: #545454;
	}

	.datatable-footer .datatable-pager .datatable-icon-left,
	.datatable-footer .datatable-pager .datatable-icon-skip,
	.datatable-footer .datatable-pager .datatable-icon-right,
	.datatable-footer .datatable-pager .datatable-icon-prev {
		font-size: 18px;
		line-height: 27px;
		padding: 0 3px;
	}

	.datatable-summary-row .datatable-body-row .datatable-body-cell {
		font-weight: 600;
	}

	.datatable-body button.icon-btn-bg.btn-edit {
		margin-top: 4px;
	}

	.datatable-body button.icon-btn-bg.btn-save {
		border-bottom: 0px;
		border-left: 0px;
	}

	.datatable-body button.icon-btn-bg.btn-cancel {
		border-bottom: 0px;
		border-left: 0px;
	}

	.datatable-icon-left {
		@extend .material-icons;

		&::after {
			content: "navigate_before";
		}
	}

	.datatable-icon-skip {
		@extend .material-icons;

		&::after {
			content: "skip_next";
		}
	}

	.datatable-icon-right {
		@extend .material-icons;

		&::after {
			content: "navigate_next";
		}
	}

	.datatable-icon-prev {
		@extend .material-icons;

		&::after {
			content: "skip_previous";
		}
	}

	.datatable-icon-up {
		align-self: center;
		margin-top: 2px;
		margin-left: 4px;
		@extend .material-icons;

		&::after {
			content: "keyboard_arrow_up";
		}
	}

	.datatable-icon-down {
		align-self: center;
		margin-top: 2px;
		margin-left: 4px;
		@extend .material-icons;

		&::after {
			content: "keyboard_arrow_down";
		}
	}
}

.ngx-datatable.bootstrap.widget {
	position: relative;
	height: calc(100% - 67px) !important;

	.datatable-header {
		background-color: lighten($tap-base-color, 100%);

		.datatable-header-cell {
			background-color: fade(lighten($tap-base-color, 90%), 60%);
			border-top-left-radius: 0.75rem;
			border-top-right-radius: 0.75rem;
			padding: 0.594rem 1rem;
			font-weight: 600;
			user-select: none;

			&.dragging {
				background-color: fade(#353535, 30%);
				opacity: 0.8;
				z-index: -1;
			}

			&:not(:first-child) {
				border-left: 1px solid lighten($tap-base-color, 100%);
			}

			&:not(:last-child) {
				border-right: 1px solid lighten($tap-base-color, 100%);
			}

			.datatable-header-cell-template-wrap {
				display: flex;
				width: calc(100% - 12px);

				.datatable-header-cell-wrapper {
					display: inline-block;
				}
			}

			i.material-icons {
				position: absolute;
				top: 32%;
				right: 12px;
				color: lighten($tap-base-color, 100%);
				font-weight: 600;
				cursor: move;
				user-select: none;
				transform: rotate(45deg);
			}
		}

		& .dragging-state {
			& > datatable-header-cell {
				&:hover:not(.dragging) {
					overflow: visible !important;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: unset;
						width: 1px;
						height: 100%;
						transform: translateX(-1px);
						border-left: 2px solid #353535;
						border-right: none;
						z-index: 12;
					}
				}

				&.dragging ~ *:hover {
					overflow: visible !important;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: unset;
						right: 0;
						width: 1px;
						height: 100%;
						transform: translateX(1px);
						border-left: none;
						border-right: 2px solid #353535;
						z-index: 12;
					}
				}
			}
		}
	}

	.datatable-body {
		background-color: lighten($tap-base-color, 99%);

		.datatable-body-cell {
			padding: 0.5rem 1rem;
			word-break: break-word; // For IE & Edge
			overflow-wrap: break-word;
			border-bottom: 1px solid $border-color-1;
		}
	}

	&.scroll-vertical {
		datatable-body {
			overflow-x: hidden;
		}
	}

	&.scroll-horizontal {
		datatable-body {
			overflow-x: auto;
		}
	}
}

//======================================  Activity Form  ====================================
.pl-4_5rem {
	padding-left: 4.5rem !important;

	@media (max-width: 575px) {
		padding-left: 1rem !important;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) and (max-width: 767px) {
		padding-left: 1rem !important;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) and (max-width: 991px) {
		padding-left: 1rem !important;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) and (max-width: 1199px) and (max-width: 1522px) {
		padding-left: 3rem !important;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) and (max-width: 1330px) {
		padding-left: 3.5rem !important;
	}

	@media (min-width: 1000px) and (max-width: 1005px) {
		padding-left: 3rem !important;
	}
}

.pl-4rem {
	padding-left: 4rem !important;

	@media (max-width: 575px) {
		padding-left: 1rem !important;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) and (max-width: 767px) {
		padding-left: 1rem !important;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) and (max-width: 991px) {
		padding-left: 1rem !important;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) and (max-width: 1199px) and (max-width: 1522px) {
		padding-left: 3rem !important;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) and (max-width: 1330px) {
		padding-left: 3.5rem !important;
	}

	@media (min-width: 1000px) and (max-width: 1005px) {
		padding-left: 3rem !important;
	}
}

.pl-3rem {
	padding-left: 3.3rem !important;

	@media (max-width: 575px) {
		padding-left: 1rem !important;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) and (max-width: 767px) {
		padding-left: 1rem !important;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) and (max-width: 991px) {
		padding-left: 1rem !important;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) and (max-width: 1199px) and (max-width: 1522px) {
		padding-left: 3rem !important;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) and (max-width: 1330px) {
		padding-left: 3.5rem !important;
	}

	@media (min-width: 1000px) and (max-width: 1005px) {
		padding-left: 3rem !important;
	}
}

.googleCalendar {
	overflow: hidden;
	position: relative;
	height: 100%;
	min-height: 300px;
}

.googleCalendar iframe {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.search-settings {
	position: absolute;
	right: 0;
	top: 2.5rem;
	margin-right: -0.9375rem;
	border-radius: 4px;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 8px 10px 1px rgba(0, 0, 0, 0.05),
		0 3px 14px 2px rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.1);
	min-width: 300px;
	background-color: #ffffff;
}

.column-settings {
	user-select: none;
}

.column-settings-label {
	line-height: 13.5px;
	color: #353535;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.3px;
}

.dropdown-move {
	top: -1px !important;
	-webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24),
		0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.06),
		0 5px 5px -3px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 8px 10px 1px rgba(0, 0, 0, 0.05),
		0 3px 14px 2px rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.1);
	min-width: initial;
}

//======================================  ngx-datatable cell-class  ====================================
.activity {
	.isOverdue {
		color: red !important;
	}

	.isDueDate {
		color: green !important;
	}

	.isDueNextFiveDays {
		color: orange !important;
	}
}

.blank-slate {
	border: 3px dashed #ccc;
	min-height: 300px;
	color: #ccc;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.64px;
	opacity: 0.6;
}

// ================================== Percent ================================
.percent-icon {
	position: relative;
	color: black;
}

.percent-icon > i {
	position: absolute;
	display: block;
	top: 18%;
	pointer-events: none;
	width: 55px;
	text-align: center;
	font-style: normal;
	right: 0px;
}

.percent-icon > input {
	padding-left: 8px;
	padding-right: 20px;
	padding-top: 4px;
}

.disabledStaff,
.disabledStaff.hover {
	background-color: #f2f2f2;
}

//======================================  dollar input  ====================================
.dollar-icon {
	position: relative;
	color: black;

	input {
		padding-left: 24px;
	}

	& > i {
		position: absolute;
		top: 0;
		margin-left: 12px;
		display: flex;
		align-items: center;
		height: 100%;
		width: auto;
		pointer-events: none;
		font-style: normal;
	}

	&-right {
		input {
			padding-left: 12px;
			padding-right: 24px;
		}

		> i {
			right: 0;
			margin-left: unset;
			margin-right: 12px;
		}
	}
}

.dollar-icon > span {
	padding-left: 20px;
	padding-right: 0;
	padding-top: 3px;
	word-break: break-all;
}

.square-meter--right > input {
	padding-left: 5px;
	padding-right: 25px;
}

.square-meter--right > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	margin-top: 14px;
	top: 0;
	pointer-events: none;
	right: 0;
	padding-right: 30px;
	font-style: normal;
}

.percent-icon--right > input {
	padding-left: 5px;
	padding-right: 22px;
}

.percent-icon--right > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	margin-top: 14px;
	top: 0;
	pointer-events: none;
	right: 0;
	padding-right: 14px;
	font-style: normal;
}

.years-icon--right > input {
	padding-left: 5px;
	padding-right: 40px;
}

.years-icon--right > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	margin-top: 14px;
	top: 0;
	pointer-events: none;
	right: 0;
	padding-right: 23px;
	font-style: normal;
}

.mid-long-label-icon--right > input {
	padding-left: 5px;
	padding-right: 80px;
}

.mid-long-label-icon--right > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	margin-top: 14px;
	top: 0;
	pointer-events: none;
	right: 0;
	padding-right: 23px;
	font-style: normal;
}

.long-label-icon--right > input {
	padding-left: 5px;
	padding-right: 107px;
}

.long-label-icon--right > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	margin-top: 14px;
	top: 0;
	pointer-events: none;
	right: 0;
	padding-right: 23px;
	font-style: normal;
}

.tap-data-range {
	max-width: 310px !important;

	&.bs-popover-top .arrow,
	&.bs-popover-bottom .arrow {
		left: 20% !important;
	}
}

[tabindex] {
	outline: none !important;
}

/*-----------------------------*\
            Overrides
\*-----------------------------*/
// Data picker
.mat-calendar-body-selected {
	background-color: var(--tap-primary-color) !important;
	color: var(--tap-h1-color) !important;
}

/*-----------------------------*\
            Utilities
\*-----------------------------*/
.cursor-pointer {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.cursor-not-allowed {
	cursor: not-allowed;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.cursor-normal {
	cursor: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.tap-divider {
	height: 1px;
}

/*-----------------------------*\
            Animations
\*-----------------------------*/
@-webkit-keyframes fadeIn {
	from {
		visibility: hidden;
	}

	to {
		visibility: visible;
	}
}

@keyframes fadeIn {
	from {
		visibility: hidden;
	}

	to {
		visibility: visible;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

.animated {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

/*-----------------------------*\
            Scroll bar
\*-----------------------------*/
/*--- Chrome and other browsers except IE ---*/
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background-color: #f0f0f0;
}

*::-webkit-scrollbar {
	position: absolute;
	width: 8px;
	height: 8px;
	background-color: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	background-color: #96b7c2; // Default
}

/*--- Firefox scrollbar ---*/
* {
	scrollbar-color: #cdcdcd #f0f0f0; // Default
	scrollbar-width: thin;
}

/*--- Switch button ---*/
.custom-control-label:hover,
.custom-control-input:hover {
	cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: #007bff;
	background-color: #007bff;
}

/** Dropdown in body for datatables */
bs-dropdown-container {
	transform: translate(-103px, -2px);

	ul.dropdown-menu::before {
		content: " ";
		position: absolute;
		right: 0;
		bottom: 100%;
		border: solid transparent;
		height: 0;
		width: 0;
		pointer-events: none;
		background-clip: padding-box;
		border-color: transparent transparent rgba(38, 41, 44, 0.16);
		border-width: 10px;
		margin-left: 4px;
		margin-right: 4px;
	}

	ul.dropdown-menu::after {
		content: " ";
		position: absolute;
		right: 0;
		bottom: 100%;
		border: solid transparent;
		height: 0;
		width: 0;
		pointer-events: none;
		background-clip: padding-box;
		border-color: transparent transparent #fff;
		border-width: 9px;
		margin-left: 5px;
		margin-right: 5px;
	}
}

/** Collapsible containers */

$primary: #349eed;

.collapse-label-background {
	background-color: inherit;
	color: inherit;
}

.collapse-background {
	background-color: none;
}

.collapse-dropdown-icon {
	display: none;
}

@media screen and (max-width: 600px) {
	.collapse-label-background {
		//background-color: $primary !important;
		color: #ffffff !important;
		margin-left: -30px;
		padding: 0 10px 0 35px;
	}

	// .collapse-background {
	// 	background-color: $primary !important;
	// 	color: #ffffff !important;
	// }

	.collapse-dropdown-icon {
		display: block;
	}
}

/** Material Drag & Drop */
.cdk-drag-preview {
	border-radius: 4px;
	padding: 10px;
	background-color: white !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	box-shadow: 0 5px 5px -3px rgba(133, 133, 133, 0.2),
		0 8px 10px 1px rgba(133, 133, 133, 0.2),
		0 3px 14px 2px rgba(133, 133, 133, 0.2);
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragging {
	margin-left: -10px !important;
	align-items: initial !important;
	justify-content: initial !important;
	box-sizing: border-box !important;
	.note-text-area {
		height: 31.5px !important;
	}
}

.crt-signature-comp {
	padding-left: 2.5cm;
	padding-right: 2.5cm;
	@media (max-width: 992px) {
		padding-left: unset;
		padding-right: unset;
	}
	.date-field-padding {
		margin-right: 50px;
		@media (max-width: 992px) {
			margin-right: unset;
		}
	}
	.name-field-padding {
		padding-right: 30px;
		@media (max-width: 992px) {
			padding-right: unset;
		}
	}
}

.pac-container {
  z-index: 9999 !important;
}

@media print {
  #printContainer {
    width: 99.9% !important;
  }
}

.crm-close-btn {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;

	&:before,
	&:after {
		position: absolute;
		content: '';
		height: 100%;
		width: 1px;
		color: inherit;
		background: currentcolor;
		top: 50%;
		left: 50%;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(315deg);
	}

}

// Pagination
// Added due to upgrade of ngx-bootstrap v9
pagination {
	.pagination-prev {
		.page-link::after {
			@extend .material-icons;
			content: "navigate_before";
			font-size: 13px;
		}
	}
	.pagination-next {
		.page-link::after {
			@extend .material-icons;
			content: "navigate_next";
			font-size: 13px;
		}
	}
}

.paragraph-br {
	br {
		content: "" !important;
		display: block !important;
		margin: .5rem 0 !important;
	}
}

@import "./scss/input-autocomplete";
@import "./scss/muuri";
@import "./scss/wysiwyg";
@import "./scss/pdf-design-v2";
@import "./scss/svg-icons";

@import "./scss/form";
@import "./scss/button";
@import "./scss/select";
@import "./scss/tabset";
@import "./scss/table";
@import "./scss/modal";

@import "./scss/util";
@import "./scss/responsive";

@import "./scss/header";

$ng-select-selected: #d3d8dd;
$ng-select-height: 0px;
@import 'node_modules/@ng-select/ng-select/scss/default.theme';

@media (max-width: 1100px) {
	.oat-note-expanded {
		.text-ne-left {
			text-align: left !important;
		}
		@include m.oat-notes-toggle-grid;
	}
}

.oat-note-expanded {
	@media (max-width: 1500px) {
		.col-lg-6 {
			max-width: 100% !important;
			&.fg-action-buttons {
				flex: 0 0 100%;
				max-width: 100% !important;
			}
		}
	}
	@media (max-width: 1092px) {
		.fg-action-buttons {
			gap: 1em !important;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			button {
				margin: 0px !important;
				min-width: 100%;
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1500px) {
	.col-lg-xl {
			flex: 0 0 90% !important;
			max-width: 90% !important;
	}
}

@media (max-width: 519px){
	.input-chips-tooltip{
		left:6px !important;
		text-align: center !important;
	}
}
