@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css');
// @import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
// $fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
// @import "~@fortawesome/fontawesome-free/scss/solid.scss";
// @import "~@fortawesome/fontawesome-free/scss/regular.scss";
// @import "~@fortawesome/fontawesome-free/scss/brands.scss";

.fa,
.fab,
.far,
.fas {
  &.main-color {
    color: #00263e;
  }
  &.light-blue {
    color: #00bbe7;
  }

  &.green {
    color: #7ed321;
  }
  &.red {
    color: #d0021b;
  }
  &.dark {
    color: #000000;
  }
  &.light {
    color: #ffffff;
  }
  &:disabled,
  &.disabled {
    filter: opacity(20%);
  }
  &.fa-14 {
    font-size: 14px;
  }
  &.fa-16 {
    font-size: 16px;
  }
  &.fa-15 {
    font-size: 15px;
  }
  &.fa-18 {
    font-size: 18px;
  }
  &.fa-20 {
    font-size: 20px;
  }
  &.fa-24 {
    font-size: 24px;
  }
  &.fa-36 {
    font-size: 36px;
  }
  &.fa-48 {
    font-size: 48px;
  }
}

:disabled {
  .fa,
  .fab,
  .far,
  .fas {
    filter: opacity(40%);
  }
}
