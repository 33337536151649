/**
* #TABLE
* 
* styles for simple table
*/

.tap-table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 3rem 0;
  padding: 0;
}


.tap-table__row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-color-1;
  min-height: 60px;
}

.tap-table__row--header {
  box-shadow: 0px 2px 6px -8px #000;
  border-bottom: none;
}

.tap-table__cell {
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 0.8125rem;
  list-style: none;
  overflow: hidden;
  padding: 0.8rem 1.2rem;
  width: 100%;
  > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
}
.tap-table__cell--header {
  font-size: 0.75rem;
  font-weight: 600;
}

/* Table column sizing
================================== */
.tap-table--2cols > .tap-table__row > .tap-table__cell  { width: 50%; }
.tap-table--3cols > .tap-table__row > .tap-table__cell  { width: 33.33%; }
.tap-table--4cols > .tap-table__row > .tap-table__cell  { width: 25%; }
.tap-table--5cols > .tap-table__row > .tap-table__cell  { width: 20%; }
.tap-table--6cols > .tap-table__row > .tap-table__cell  { width: 16.6%; }

