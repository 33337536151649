/** HEADER REWORK */
.menu-open {
  .tap-header .header-nav-toggle .tap-icon {
    color: var(--tap-primary-color);
  }
}
.tap-header {
	height: $tap-header-height;
  border-top: $tap-header-colorbar-height solid; 
  border-bottom: 1px solid $tap-color-gray-light;
  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
	.header-nav-toggle {
    margin-left: 15px;
    color: $tap-header-colorbar-height;
  }
  .header-logo-container, .header-nav {
		height: calc($tap-header-height - $tap-header-colorbar-height) !important;
	}
  .header-logo-container {
    display: flex;
    #logoLink {
      padding: 15px;
      margin-right: 10px;
    }
  }

  .push-to-right {
    margin-left: auto;
  }

  /** header nav on mobile view */
  .sidenav {
    background-color: white;
    position: absolute;
    z-index: 20;
    height: 100% !important;
    width: 300px !important;
    border: 1px solid $tap-color-gray-light;
    background: white;
    ul {
      padding: 10px;
      height: unset !important;
      > li {
        > div {
          width: 100%;
          &:hover {
            background-color: $tap-color-gray-light;
            border-radius: 50px;
          }
        }
        border-bottom: 1px solid $tap-color-gray-light;
        &.nav-item.dropdown.open {
          &:hover {
            background-color: white !important;
          }
        }
        > div {
          padding: 10px;
          width: 100%;
          a.nav-link {
            flex-grow: 1;
            justify-content: start !important;
          }
          mat-icon.tap-icon, svg, a.nav-link {
            pointer-events: none !important;
          }
        }
        mat-icon.tap-icon {
          color: var(--tap-primary-color) !important;
        }
        > ul {
          padding: 0 10px;
          width: 100%;
          height: auto !important;
          > li{
            border-bottom: none;
          }
        }
      }
    }
    &::backdrop {
      background-color: red;
    }
  }

}

/** Responsive */
.tap-header:not(.desktop-view) {
  .header-content {
    justify-content: start !important;
  }
  .activity-button {
    margin-left: auto;
  }
}
.tap-header.tablet-view {
  height: $tap-header-tablet-height !important;
  display: flex;
  flex-direction: column;
  .header-content {
    height: 60px;
    border-bottom: 1px solid $tap-color-gray-light;
  }
  app-header-nav {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.tap-header.mobile-view {
  &:not(.tap-admin) {
    .header-logo-container {
      #logoLink {
        padding-left: 0;
      }
    }
    .header-nav-toggle {
      margin-left: 0;
    }
  }
  
  .header-toggle {
    margin-right: 0;
  }
}
