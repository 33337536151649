.selectmenu {
  & ~ .ui-selectmenu-button {
    display: flex;
    // background-color: var(--tap-field-bg-color, #ffffff);
    // color: var(--tap-field-text-color, #000000);
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 27px;
    padding: 0.25rem 0.75rem;
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;

    &:focus {
      outline: none;
    }

    .ui-selectmenu-icon {
      @extend .material-icons;

      position: absolute;
      right: 0;
      // margin-right: 4px;
      // display: none;
      background-image: unset;
      text-indent: unset;
      color: #d5dee1;
      line-height: 1.5;

      &::after {
        content: 'expand_more';
      }
    }

    .ui-selectmenu-text {
      margin-right: 0;
    }
  }

  &.selectmenu--text-center ~ .ui-selectmenu-button {
    justify-content: center;
  }

  &.selectmenu--font-weight-500 ~ .ui-selectmenu-button {
    font-weight: 600;
  }

  &.selectmenu--rounded-pill ~ .ui-selectmenu-button {
    border-radius: 50rem;
  }

  &.selectmenu--h-39 ~ .ui-selectmenu-button {
    height: 39px;
  }
}

.ui-selectmenu-menu {
  .ui-menu-item {
    .ui-state-active {
      border: 0;
      background: var(--tap-bg-primary, #007fff);
      color: var(--tap-dropdown-text-color, #ffffff);
    }
  }
}
