@use 'sass:math';

$formBtnHeight: 35px;
$formBtnMinWidth: 115px;

@mixin form-buttons {
  .form-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -23px;
    margin-right: -160px;

    width: $formBtnMinWidth;
    height: auto;
  }

  .form-buttons > button {
    height: $formBtnHeight;
  }

  @media screen and (max-width: 1115px) {
    .form-buttons {
      position: absolute;
      bottom: 0;
      right: unset;
      left: 0;
      margin-bottom: -120px;
      margin-right: unset;
      margin-left: -24px;
      width: calc(100% + 48px);
    }
  }
}


// ne = note expanded
@mixin oat-notes-toggle-grid($columns: 12, $infix: "-ne", $gutter: 30px) {

	@for $i from 1 through $columns {
		.col#{$infix}-#{$i} {
			flex: 0 0 math.percentage(math.div($i, $columns)) !important;
			max-width: math.percentage(math.div($i, $columns)) !important;
		}
	}

	@for $i from 0 through ($columns - 1) {
		@if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
			.offset#{$infix}-#{$i} {
				$num: math.div($i, $columns);
				margin-left: if($num == 0, 0, math.percentage($num));
			}
		}
	}
}
