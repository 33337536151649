@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	src: url(../assets/fonts/OpenSans-Light.woff),
		url(../assets/fonts/OpenSans-Light.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	font-weight: normal;
	src: url(../assets/fonts/OpenSans-Regular.woff2),
		url(../assets/fonts/OpenSans-Regular.woff),
		url(../assets/fonts/OpenSans-Regular.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	src: url(../assets/fonts/OpenSans-Regular.woff2),
		url(../assets/fonts/OpenSans-Regular.woff),
		url(../assets/fonts/OpenSans-Regular.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	font-weight: 600;
	src: url(../assets/fonts/OpenSans-SemiBold.woff2),
		url(../assets/fonts/OpenSans-SemiBold.woff),
		url(../assets/fonts/OpenSans-SemiBold.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	font-weight: bold;
	src: url(../assets/fonts/OpenSans-Bold.woff2),
		url(../assets/fonts/OpenSans-Bold.woff),
		url(../assets/fonts/OpenSans-Bold.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	src: url(../assets/fonts/OpenSans-Bold.woff2),
		url(../assets/fonts/OpenSans-Bold.woff),
		url(../assets/fonts/OpenSans-Bold.ttf);
	font-display: swap;
}

* {
	font-family: "Open Sans", sans-serif;
}

$sizes: (
	8: 8px,
	10: 10px,
	12: 12px,
	13: 13px,
	14: 14px,
	16: 16px,
	18: 18px,
	20: 20px,
	22: 22px,
	24: 24px,
	30: 30px,
);

// Font Sizes
@each $sizeKey, $sizeVal in $sizes {
	.tap-fs-#{$sizeKey} {
		font-size: #{$sizeVal} !important;
	}
}

$weights: (
	light: 300,
	normal: 400,
	bold: 600,
	bolder: 700,
);

// Font Weights
@each $weightKey, $weightVal in $weights {
	.tap-fw-#{$weightKey} {
		font-weight: #{$weightVal} !important;
	}
}

// v2 text classes
// https://www.figma.com/design/ePjstw3hbhxrCHS0xoU28K/The-Adviser-Platform-Component-Library-Master-File?node-id=69-12281&t=ABZvq25xiMMxZhW3-0
[class*="tap-text"] {
  letter-spacing: normal;
	line-height: normal;
	margin: 0;
}
.tap-text {
	font-size: 14px;
	font-weight: 400;Q
	// TODO font-face Poppins, Inter
	&-header {
		// font-family: Poppins
		font-size: 18px;
		font-weight: 500;
	}
	&-number {
		// font-family: Poppins
		font-size: 25px;
		font-weight: 500;
	}
	&-small {
		// font-family: Inter
		font-size: 12px;
		font-weight: 400;
		&-bold {
			font-weight: bold;
		}
	}
	&-nav, &-body {
		// font-family: Inter
		font-size: 14px;
		font-weight: 400;
	}
}