.froala-template-file {
	.car-policy-pdf-template {
		margin-right: 0px !important;
		.car-template-summary-table {
			width: 100% !important;
		}


		.full-width-right-spacing {
			padding-right: 91px !important;
		}

		.full-width-left-spacing {
			padding-left: 91px !important;
		}

		.full-width-container {
			* {
				max-width: unset !important;
			}
			width: 899px !important;
			position: relative !important;
			// padding-right: 0 !important;
			// padding-left: 84px !important;
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			left: -84px !important;
			max-width: unset !important;
			margin-top: 30px;
			border: none;
			background-color: var(--tap-bg-primary);

			table {
				width: 727px !important;
			}
		}

		.policy-table {
			td {
				font-size: 12px !important;
			}
			td.currency-cell {
				text-align: right !important;
			}
		}
	}

	.pdf-design-v2 {
		* {
			font-family: "Verdana" !important;
			letter-spacing: normal !important;
		}
		&.pdf-design-v2-body,
		&.pdf-design-v2-default-generic-cover {
			width: 100%;
			max-width: 630px;
			margin-left: 84px;
		}
		&.pdf-design-v2-default-generic-cover {
			.pdf-design-v2-generic-title {
				margin-top: 200px;
			}
			.pdf-design-v2-generic-bottom {
				position: absolute;
				top: 760px;
				width: 100%;
			}
		}
		&.pdf-design-v2-custom-generic-cover {
			padding-left: 85px;
			width: 815px;
			// height: 1150px; // w/o page header
			height: 1054px; // w/ page header
			margin: 0px;
			overflow: hidden;
			.pdf-design-v2-generic-cover-details {
				// padding-left: 84px;
				// padding-right: 84px;
			}
			.pdf-design-v2-generic-img {
				display: none;
			}
			.pdf-design-v2-generic-cover-bg {
				height: 45%;
				width: 100%;
				display: block;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
		}
		.pdf-design-v2-generic-bottom {
			&.tap-text-primary {
				* {
					color: var(--tap-primary-color) !important;
				}
			}
		}
		.pdf-design-v2-generic-img {
			img {
				max-height: 130px;
			}
		}
		.pbreak-inside-avoid {
			page-break-inside: avoid;
		}
		h1 {
			font-weight: 500;
			font-style: normal;
			border-bottom-color: var(--tap-primary-color) !important;
			letter-spacing: normal;
		}
		h2,
		h3 {
			letter-spacing: normal;
			font-weight: normal;
		}
		h4 {
			letter-spacing: normal;
			&.reg {
				font-weight: normal;
			}
		}
		.h1-no-pagebreak {
			border-bottom-color: var(--tap-primary-color) !important;
			font-weight: 500;
			font-style: normal;
			letter-spacing: normal;
			font-size: 22px;
			color: #353535 !important;
		}
		p,
		table td,
		table th,
		span,
		ul li {
			font-size: 14px;
			letter-spacing: normal !important;
		}
		ul.sos-cbox {
			margin-top: -50px;
		}
		ul.sos-cbox li {
			.service {
				p {
					padding-bottom: 0px;
				}
				span {
					font-size: 13px;
				}
			}
		}
		span[style="font-size: 60px;"] strong span {
			font: 60px important;
		}
		span[style="font-size: 24px;"] strong span {
			font: 24px important;
		}
		ul li {
			list-style-type: disc;
		}
		table.car-template-summary-table {
			tr {
				border: 0px solid transparent !important;
			}
			td.align-right {
				text-align: right !important;
				padding-top: 5px !important;
				padding-bottom: 5px !important;
				margin-top: 0px !important;
				margin-bottom: 0px !important;
				border: none !important;
			}
		}
		table {
			// border-color: #f2f3f2;
			border: none !important;
			td,
			th {
				// border-color: #f2f3f2;
				padding: 10px 4px 4px 4px !important;
			}
			th {
				background-color: #f2f3f2;
				font-weight: normal !important;
				border: none !important;
			}
			td:first-child {
				border-top: 0px;
			}
			&:not(.car-table) tbody tr td {
				text-align: left !important;
			}
			tbody tr {
				&.header-fill {
					background-color: #f2f3f2;
				}
				border-bottom: 1px solid #f2f3f2;
				td {
					border: none !important;
				}
				&.no-data-display {
					td {
						text-align: center !important;
					}
				}
			}
			&.total {
				tbody tr {
					td {
						background-color: #f2f3f2;
						font-weight: normal !important;
						border: none !important;
					}
					.strong {
						font-weight: normal !important;
					}
				}
			}
			&.full-width-primary-bg-table {
				width: 815px !important;
				position: relative !important;
				left: -84px !important;
				max-width: unset !important;
				margin-top: 30px;
				border: none;
				background-color: var(--tap-bg-primary);
				td,
				th {
					color: var(--tap-h1-color);
					border: none;
					padding: 20px 6px !important;
				}
				td:first-child {
					padding-left: 84px !important;
				}
			}
		}
		ul.ul-indent {
			li {
				margin-left: 20px;
			}
		}
		.custom-control-rbox {
			.rbox {
				width: 20px;
				height: 20px;
				border: 3px solid white;
				appearance: none;
				border-radius: 50%;
				background-color: #fff;
				transition: all ease-in 0.2s;
				box-shadow: 0 0 0 1px var(--tap-oat-input-color);
				&:checked {
					box-shadow: 0 0 0 1px var(--tap-bg-primary);
					background-color: var(--tap-bg-primary);
				}
			}
		}
	}
		
	table.dynamicDependants {
		td:first-child, th:first-child {
			width: 230px !important;
		}
	}
}
