@mixin green-check-svg-icon {
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10-4.5-10-10-10m0,18c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8-3.59,8-8,8M16.59,7.58L10,14.17L7.41,11.59L6,13l4,4l8-8-1.41-1.42Z" transform="matrix(1.2 0 0 1.2-2.4-2.4)" fill="%2373c04d"/></svg>') no-repeat;
	display: block;
}
@mixin red-cross-svg-icon {
	background: url('data:image/svg+xml;utf8,<svg id="eeuY9pVh4Df1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><path d="M20,20L41,41" fill="none" stroke="%23f00" stroke-width="5"/><path d="M20,41L41,20" fill="none" stroke="%23f00" stroke-width="5"/><circle r="27.5" transform="translate(30 30)" fill="none" stroke="%23fe0f0e" stroke-width="5"/></svg>') no-repeat;
	display: block;
}

.green-check-svg {
	&-30 {
		@include green-check-svg-icon;
		height: 20px;
		width: 20px;
	}
	&-50 {
		@include green-check-svg-icon;
		height: 50px;
		width: 50px;
	}
}

.red-cross-svg {
	&-30 {
		@include red-cross-svg-icon;
		height: 20px;
		width: 20px;
	}
	&-50 {
		@include red-cross-svg-icon;
		height: 50px;
		width: 50px;
	}
}

.tap-icon {
	&:not(.svg-no-fill) {
		svg, svg path {
			fill: currentColor;
		}
	}
	svg stroke {
		fill: currentColor;
	}
}