@import "variable";

/**
* #BUTTON
*
* get rid of other styles after whole UI redesign
*/

.tap-btn {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #ffffff;
  border: 1px solid transparent;
  padding: 0.25rem 1.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  box-shadow: none;
  box-shadow: inset 0 2px 13px rgba(0, 0, 0, 0.025);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    filter: brightness(90%);
    box-shadow: none;
  }
  &:active {
    filter: brightness(80%);
    //box-shadow: none;
  }
  &:focus {
    filter: brightness(100%);
    //box-shadow: none;
  }
  &:disabled {
    filter: brightness(90%);
    box-shadow: none;
    opacity: 0.6;
    cursor: not-allowed;
  }

  &--base {
    color: var(--tap-h4-color);
    background-color: #00263e;
    border-color: #00263e;
  }

  &--primary {
    color: var(--tap-h4-color);
    background-color: var(--tap-primary-color);
    border-color: var(--tap-primary-color);
  }

  &--secondary {
    color: var(--tap-h4-color);
    background-color: var(--tap-secondary-color);
    border-color: var(--tap-secondary-color);
  }

  &--tertiary {
    color: var(--tap-h4-color);
    background-color: var(--tap-tertiary-color);
    border-color: var(--tap-tertiary-color);
  }

  &--default {
    // color: var(--tap-text-color);
    // background-color: var(--tap-bg-color);
    // border-color: var(--tap-text-color);
    color: #545d70;
    border-color: #d9e3ed;
    &--darker {
      background-color: #c0c0c0;
    }

    &:hover {
      background-color: #eaf0f6;
    }
    &:disabled {
      background-color: #d9e3ed;
    }
  }

  &-outline {
    background-color: transparent !important;
    border-radius: 2px;
    border: none;
  }

  &--shadow {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      filter: brightness(90%);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &--block {
    display: block;
    width: 100%;
  }
}

[data-theme="dark"] .tap-btn--default {
  --tap-text-color: #ffffff;
  color: var(--tap-text-color);
  border-color: var(--tap-text-color);
}
