/* https://material.io/resources/icons/?style=baseline */
// @import url("https://fonts.googleapis.com/css2?family=Material+Icons");
/* https://material.io/resources/icons/?style=outline */
// @import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
/* https://material.io/resources/icons/?style=round */
// @import url("https://fonts.googleapis.com/css2?family=Material+Icons+Round");
/* https://material.io/resources/icons/?style=sharp */
// @import url("https://fonts.googleapis.com/css2?family=Material+Icons+Sharp");
/* https://material.io/resources/icons/?style=twotone */
// @import url("https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone");

// /* fallback */
@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url("https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2")
			format("woff2"),
		url("~material-design-icons/iconfont/MaterialIcons-Regular.woff2")
			format("woff2");
	font-display: swap;
}

.material-icons {
	font-family: "Material Icons";
}

@font-face {
	font-family: "Material Symbols Outlined";
	font-style: normal;
	font-weight: 100 700;
	src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v119/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
		format("woff2");
}

.material-symbols-outlined {
	font-family: "Material Symbols Outlined";
}

// .material-icons,
// .material-icons-outlined,
// .material-icons-round,
// .material-icons-sharp,
// .material-icons-two-tone {
.material-icons,
.material-symbols-outlined {
	font-weight: normal;
	font-style: normal;
	// font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;

	&.main-color {
		color: #00263e;
	}
	&.light-blue {
		color: #00bbe7;
	}

	&.green {
		color: #7ed321;
	}
	&.red {
		color: #d0021b;
	}
	&.dark {
		color: #000000;
	}
	&.light {
		color: #ffffff;
	}
	&.warning {
		color: #ffa500;
	}
	&:disabled,
	&.disabled {
		filter: opacity(20%);
	}
	&.md-14 {
		font-size: 16px;
	}
	&.md-16 {
		font-size: 16px;
	}
	&.md-18 {
		font-size: 18px;
	}
	&.md-20 {
		font-size: 20px;
	}
	&.md-24 {
		font-size: 24px;
	}
	&.md-36 {
		font-size: 36px;
	}
	&.md-48 {
		font-size: 48px;
	}
}
// :disabled .material-icons,
// :disabled .material-icons-outlined,
// :disabled .material-icons-round,
// :disabled .material-icons-sharp,
// :disabled .material-icons-two-tone {
:disabled .material-icons {
	filter: opacity(40%);
}
