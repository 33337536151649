@import "variable";

/**
* util classes
* there are other util classes in theme component
*/
.a-table__header-cell.tap--bg-primary {
	background-color: var(--tap-bg-primary);
	color: #ffffff;
}
.a-table__header-cell.tap--bg-inactive {
	background-color: #d5dee1;
	color: var(--tap-h5-color);
}

.tap-bg-color {
	background-color: var(--tap-bg-color) !important;
}
.tap-text-color {
	color: var(--tap-text-color) !important;
}

.tap-bg-primary {
	background-color: var(--tap-bg-primary) !important;
}
.tap-bg-secondary {
	background-color: var(--tap-bg-secondary) !important;
}
.tap-bg-tertiary {
	background-color: var(--tap-bg-tertiary) !important;
}

.tap-border-color-primary {
	border-color: var(--tap-bg-primary) !important;
}
.tap-border-color-secondary {
	border-color: var(--tap-bg-secondary) !important;
}
.tap-border-color-tertiary {
	border-color: var(--tap-bg-tertiary) !important;
}

.tap-text-primary {
	color: var(--tap-text-primary) !important;
}
.tap-text-primary-fill {
	fill: var(--tap-text-primary) !important;
}
.tap-text-secondary {
	color: var(--tap-text-secondary) !important;
}
.tap-text-tertiary {
	color: var(--tap-text-tertiary) !important;
}
.tap-text-link {
	color: var(--tap-text-link) !important;
}

.tap-text-h1 {
	font-size: var(--tap-h1) !important;
}
.tap-text-h2 {
	font-size: var(--tap-h2) !important;
}
.tap-text-h3 {
	font-size: var(--tap-h3) !important;
}
.tap-text-h4 {
	font-size: var(--tap-h4) !important;
}
.tap-text-h5 {
	font-size: var(--tap-h5) !important;
}
.tap-text-h6 {
	font-size: var(--tap-h6) !important;
}

.tap-text-color-h1 {
	color: var(--tap-h1-color) !important;
}
.tp-text-color-h2 {
	color: var(--tap-h2-color) !important;
}
.tap-text-color-h3 {
	color: var(--tap-h3-color) !important;
}
.tap-text-color-h4 {
	color: var(--tap-h4-color) !important;
}
.tap-text-color-h5 {
	color: var(--tap-h5-color) !important;
}
.tap-text-color-h6 {
	color: var(--tap-h6-color) !important;
}

.tap-bg-h1 {
	background-color: var(--tap-h1-color) !important;
}
.tap-bg-h2 {
	background-color: var(--tap-h2-color) !important;
}
.tap-bg-h3 {
	background-color: var(--tap-h3-color) !important;
}
.tap-bg-h4 {
	background-color: var(--tap-h4-color) !important;
}
.tap-bg-h5 {
	background-color: var(--tap-h5-color) !important;
}
.tap-bg-h6 {
	background-color: var(--tap-h6-color) !important;
}

.tap-border-color-h1 {
	border-color: var(--tap-h1-color) !important;
}
.tap-border-color-h2 {
	border-color: var(--tap-h2-color) !important;
}
.tap-border-color-h3 {
	border-color: var(--tap-h3-color) !important;
}
.tap-border-color-h4 {
	border-color: var(--tap-h4-color) !important;
}
.tap-border-color-h5 {
	border-color: var(--tap-h5-color) !important;
}
.tap-border-color-h6 {
	border-color: var(--tap-h6-color) !important;
}

.tap-text-light1 {
	color: var(--tap-light1-color) !important;
}
.tap-text-light2 {
	color: var(--tap-light2-color) !important;
}
.tap-text-light3 {
	color: var(--tap-light3-color) !important;
}
.tap-text-light4 {
	color: var(--tap-light4-color) !important;
}
.tap-text-light5 {
	color: var(--tap-light5-color) !important;
}

.tap-bg-light1 {
	background-color: var(--tap-light1-color) !important;
}
.tap-bg-light2 {
	background-color: var(--tap-light2-color) !important;
}
.tap-bg-light3 {
	background-color: var(--tap-light3-color) !important;
}
.tap-bg-light4 {
	background-color: var(--tap-light4-color) !important;
}
.tap-bg-light5 {
	background-color: var(--tap-light5-color) !important;
}

.tap-border-color-light1 {
	border-color: var(--tap-light1-color) !important;
}
.tap-border-color-light2 {
	border-color: var(--tap-light2-color) !important;
}
.tap-border-color-light3 {
	border-color: var(--tap-light3-color) !important;
}
.tap-border-color-light4 {
	border-color: var(--tap-light4-color) !important;
}
.tap-border-color-light5 {
	border-color: var(--tap-light5-color) !important;
}

.tap-text-dark1 {
	color: var(--tap-dark1-color) !important;
}
.tap-text-dark2 {
	color: var(--tap-dark2-color) !important;
}
.tap-text-dark3 {
	color: var(--tap-dark3-color) !important;
}
.tap-text-dark4 {
	color: var(--tap-dark4-color) !important;
}
.tap-text-dark5 {
	color: var(--tap-dark5-color) !important;
}
.tap-text-dark6 {
	color: var(--tap-dark6-color) !important;
}
.tap-text-dark7 {
	color: var(--tap-dark7-color) !important;
}

.tap-bg-dark1 {
	background-color: var(--tap-dark1-color) !important;
}
.tap-bg-dark2 {
	background-color: var(--tap-dark2-color) !important;
}
.tap-bg-dark3 {
	background-color: var(--tap-dark3-color) !important;
}
.tap-bg-dark4 {
	background-color: var(--tap-dark4-color) !important;
}
.tap-bg-dark5 {
	background-color: var(--tap-dark5-color) !important;
}
.tap-bg-dark6 {
	background-color: var(--tap-dark6-color) !important;
}
.tap-bg-dark7 {
	background-color: var(--tap-dark7-color) !important;
}

.tap-border-color-dark1 {
	border-color: var(--tap-dark1-color) !important;
}
.tap-border-color-dark2 {
	border-color: var(--tap-dark2-color) !important;
}
.tap-border-color-dark3 {
	border-color: var(--tap-dark3-color) !important;
}
.tap-border-color-dark4 {
	border-color: var(--tap-dark4-color) !important;
}
.tap-border-color-dark5 {
	border-color: var(--tap-dark5-color) !important;
}
.tap-border-color-dark6 {
	border-color: var(--tap-dark6-color) !important;
}
.tap-border-color-dark7 {
	border-color: var(--tap-dark7-color) !important;
}

.tap-bg-transparent {
	background-color: transparent !important;
}

.tap-opacity-25 {
	opacity: 0.25;
}
.tap-opacity-50 {
	opacity: 0.5;
}
.tap-opacity-75 {
	opacity: 0.75;
}
.tap-opacity-100 {
	opacity: 1;
}

.scrollable {
	&-auto {
		overflow: auto !important;
		&-x {
			overflow-x: auto !important;
		}
		&-y {
			overflow-y: auto !important;
		}
	}

	&-hidden {
		overflow: hidden !important;
		&-x {
			overflow-x: hidden !important;
		}
		&-y {
			overflow-y: hidden !important;
		}
	}
}

.disabled {
	opacity: 0.7;
	pointer-events: none !important;
	cursor: not-allowed;
}

// Bootstrap Overrides
.font-weight-bold,
th {
	font-weight: 600 !important;
}

.font-weight-bolder {
	font-weight: 700 !important;
}

strong {
	font-weight: 600 !important;
}

button,
input,
optgroup,
select,
textarea {
	font-family: var(--tap-font, "Open Sans", sans-serif) !important;
}

// Spaces in px
$spaces: (
	10: 10px,
	15: 15px,
	20: 20px,
	25: 25px,
	30: 30px,
);

// Sides
$sides: (
	t: top,
	b: bottom,
	l: left,
	r: right,
);

// Custom Margins
@each $spaceKey, $spaceVal in $spaces {
	@each $sideKey, $sideVal in $sides {
		.m#{$sideKey}-#{$spaceKey} {
			margin-#{$sideVal}: #{$spaceVal} !important;
		}
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.m-#{$spaceKey} {
		margin: #{$spaceVal} !important;
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.mx-#{$spaceKey} {
		margin-left: #{$spaceVal} !important;
		margin-right: #{$spaceVal} !important;
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.my-#{$spaceKey} {
		margin-top: #{$spaceVal} !important;
		margin-bottom: #{$spaceVal} !important;
	}
}

// Custom Paddings
@each $spaceKey, $spaceVal in $spaces {
	@each $sideKey, $sideVal in $sides {
		.p#{$sideKey}-#{$spaceKey} {
			padding-#{$sideVal}: #{$spaceVal} !important;
		}
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.p-#{$spaceKey} {
		padding: #{$spaceVal} !important;
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.px-#{$spaceKey} {
		padding-left: #{$spaceVal} !important;
		padding-right: #{$spaceVal} !important;
	}
}
@each $spaceKey, $spaceVal in $spaces {
	.py-#{$spaceKey} {
		padding-top: #{$spaceVal} !important;
		padding-bottom: #{$spaceVal} !important;
	}
}
