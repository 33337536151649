@import "variable";

$border-gray: #efefef;

.bs-modal-backdrop {
	opacity: 0.95 !important;
	background: var(--tap-primary-color) !important;
}

.modal-body {
	position: relative;
	padding: 0;

	.fallback {
		background-color: #ffffff;
		word-break: break-word;
	}
}

.modal-footer {
	margin: 0;
	text-align: center;
}

//for modal cancel or close button
// .modal-btn-cancel {
//   font-weight: 600 !important;
//   background-color: var(--tap-secondary-color) !important;
//   color: var(--tap-field-text-color) !important;
//   border-radius: 20px ;
//   font-weight: 600;
//   width: 130px;
//   letter-spacing: 0.32px;
//   font-size: 15px;
// }

// For modal primary action like upload,delete,yes and so on..
// .modal-btn-confirm {
//   background-color: var(--tap-bg-primary) !important;
//   border: 1px solid var(--tap-bg-primary) !important;
//   color: var(--tap-field-text-color) !important;
//   border-radius: 20px;
//   font-weight: 600 !important;
//   width: 130px !important;
//   letter-spacing: 0.32px;
//   font-size: 15px;
// }

.modal {
	background-color: rgb(0, 0, 0, 0.32);

	&-backdrop {
	}

	&-dialog {
		&-xl {
			max-width: 1300px;
			width: 90vw;
		}
		&-outlook-xl {
			max-width: 1775px;
			width: 97vw;
		}
	}

	&-content {
		padding: 0.75rem;
		border: 0;
		border-radius: 3px;
		background-color: #ffffff;
	}

	&-workflow {
		.modal {
			&-content {
				padding: 0rem;
			}
		}
	}

	&-full {
		margin: 0;
		max-width: 100vw;
		min-height: 100vh;
		.modal {
			&-content {
				padding: 0rem;
				height: 100vh;
			}
			&-body {
				height: calc(100vh - 59px);
			}
		}
	}

	&-btn-close {
		display: none;
	}
	// &-with-close {
	&-btn-close {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		background-color: #ffffff !important;
		width: 24px;
		height: 24px;
		border-radius: 50rem;
		padding: 2.4px;
		margin-top: -12px;
		margin-right: -12px;
		border: 1px solid #afafaf;
		opacity: 0.8;
		transition: opacity 0.3s eas-out, background-color 0.3s ease-out;

		&:hover {
			opacity: 1;
		}
		.material-icons {
			font-size: 18px;
			// color: #545d70;
			color: #9a9da5;
			font-weight: 600;
		}
	}
	// }
	&-header {
		padding: 0 0 0.75rem 0;
		border: 0;
		border-radius: 0;
		letter-spacing: 0.64px;
		background-color: transparent;
		.tap-divider {
			width: 120% !important;
		}
	}
	&-title {
		padding: 0;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.73px;
		color: var(--tap-secondary-color);
	}
	&-body {
		// padding: .75rem 0 .75rem 0;
		padding: 0;
	}

	&-footer {
		border: 0;
		padding: 0.75rem 0 0 0;
	}
}

app-date-input input,
.form-control,
.chips-control__container {
	border-radius: 4px;
	// background-color: var(--tap-search-bg-input) !important;
	// border: 1px solid var(--tap-search-bg-input) !important;
	background-color: #ffffff;
	border: 1px solid #d9e3ed;
	box-shadow: inset 0 2px 13px rgba(0, 0, 0, 0.025);
}

app-date-input input {
	color: var(--tap-search-input-color);
}

// Custom sizing
@media (min-width: 576px) {
	.modal-dialog.modal-advice-process-summary {
		max-width: 700px;
	}
}

//Required highlight on load for Moat V2 modals
.moat-v2-dialog form {
	.ng-invalid,
	.ng-pending {
		border-color: $invalid-color !important;
	}

	//People
	app-date-input.ng-invalid input,
	app-address-input.ng-invalid input,
	app-chips.ng-invalid .chips-control__container {
		border-color: $invalid-color !important;
	}

	//Asset & Liability
	.ng-invalid {
		&#propertyValue,
		&#kiwiSaverProvider,
		&#kiwisaverCurrentBalance,
		&#propertyAddressAdd {
			border-color: $invalid-color !important;
		}
	}

	//highlight on non-required fields on Edit mode fix
	.ng-invalid.ng-pristine {
		&#dateOfBirth,
		&#purchaseDate,
		&#securityValuationDate,
		&#fixedPeriodEnd,
		&#loanRepaymentDate {
			border-color: transparent !important;
		}
	}
}

// MODAL REWORK / Modal V2
.modal-dialog:has(.modal-content .tap-modal) {
	max-width: 535px;
}
.tap-modal {
	padding: 0 3px;
	.tap-modal-header {
		display: grid;
		justify-content: unset;
		justify-items: center;
		padding-bottom: 19px;
		.tap-btn.tap-modal-close {
			border: 1px solid #03263f;
			color: #03263f;
			height: 23px;
			width: 23px;
			padding: 0;
			margin: 0;
			margin-left: auto;
			border-radius: 0;
			span.material-icons {
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 23px;
				justify-self: right;
			}
		}
		.modal-title {
			color: #212629;
			font-size: 16px;
			line-height: 27px;
			font-weight: 600;
		}
	}
	.tap-modal-body {
		display: grid;
		justify-content: unset;
		justify-items: center;
		margin-bottom: 20px;
		p {
			text-align: center;
			font-size: 14px;
			line-height: 22px;
			font-weight: 300;
		}
	}
	.tap-modal-footer {
		display: grid;
		justify-content: unset;
		justify-items: center;
		margin-bottom: 21px;
		.tap-btn {
			color: #212629;
			font-size: 14px;
			border-color: #d7d7d7;
		}
	}
}
.oat-frequency-modal {
	.tap-modal {
		.tap-modal-header {
			.modal-title {
				margin-top: 15px;
			}
		}
		.tap-modal-body {
			margin-bottom: unset;
		}
		.tap-modal-footer {
			margin-bottom: 25px;
		}
	}
}

// Design v3; Sample: TAP1-5600
.modal-dialog-v3 {
	padding: 0px;
	.modal-content {
		padding: 0px;
		border-radius: 15px;
		line-height: normal;
	}
	.modal-btn-close {
		top: 22px;
		right: 22px;
		border: 0px;
		opacity: 1;
		height: 26px;
		width: 26px;
		font-weight: normal;
		.material-icons {
			font-weight: normal;
			font-size: 21px;
			color: $tap-color-black;
		}
	}
	.modal-header {
		padding: 20px 45px 3px 20px;
		h1.modal-title {
			font-size: 14px;
			color: $tap-color-black;
			line-height: normal;
		}
	}
	.modal-body {
		padding: 3px 20px 20px 20px;
		color: $tap-color-black;
		word-break: break-word;
		p {
			font-size: 14px;
			letter-spacing: normal;
		}
	}
	.modal-footer {
		padding: 15px 17px;
		border-top: 1px solid $border-gray;
		justify-content: center;
		align-content: flex-start;
		gap: 5px;
		.tap-btn {
			border-radius: 5px;
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 auto;
			justify-content: center;
			padding: 7px;
			&.tap-btn--default {
				border: 1px solid $border-gray;
			}
			&.tap-btn--primary {
				color: white;
				border: 1px solid var(--tap-primary-color);
			}
		}
	}
}
