input[data-autocompleted] {
	background-color: transparent !important;
}

/**
 * Header > Global Search 
*/
.global-search {
	input.global-search-input:-webkit-autofill,
	input.global-search-input:-webkit-autofill:hover,
	input.global-search-input:-webkit-autofill:focus,
	input.global-search-input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 50px var(--tap-bg-primary) inset !important;
		-webkit-text-fill-color: var(--tap-h1-color) !important;
	}
}

/**
 * CRM PART (All except OAT)
 * Client & Business > Profile Tabs & Modals etc
*/
.modal-dialog:not(:has(.crt-form-control)),
main:not(:has(.crt-main-page)) {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 50px var(--tap-white) inset !important;
		-webkit-text-fill-color: var(--tap-webkit-text-fill-color) !important;
	}
	input:disabled:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px var(--tap-white) inset !important;
	}
}

/**
 * OAT PART
*/
.modal-dialog:has(.crt-form-control),
.crt-main-page {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 50px var(--tap-oat-input-bg) inset !important;
		-webkit-text-fill-color: var(--tap-oat-input-color) !important;
	}

	input:disabled:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px var(--tap-oat-input-bg) inset !important;
	}
}
