@import "svg-icons";

.froala-template-file {
	font-family: var(--tap-font);
	font-weight: normal;
	letter-spacing: normal;
	font-size: 12px;
	&.pdf-portrait {
		*:not(.full-width-pdf) {
			max-width: 643px !important;
		}
	}
	&.pdf-landscape {
		* {
			max-width: 980px !important;
		}
	}

	// Froala styles
	.wysiwyg-header-style {
		border-bottom: 1px solid #a7a7a7;
		font-size: 22px;
	}

	.froala-border-box {
		border: 1px solid #ccc;
		padding: 5px;
	}

	.froala-border-box-span {
		border: 1px solid #ccc;
		padding: 5px;
		display: block;
	}

	.froala-text-border-bottom {
		border-bottom: 1px solid var(--tap-bg-primary);
		padding-bottom: 10px;
		margin-bottom: 10px;
		display: block;
		width: 100%;
	}

	hr,
	.generated-pagebreak {
		page-break-before: always;
		border: none;
		border-color: transparent;
		color: transparent;
	}

	// Basic styles
	p {
		word-break: break-word;
	}

	var {
		font-style: normal;
	}

	ul,
	ol {
		padding-left: 20px;
		li {
			p {
				display: initial;
			}
		}
	}

	ul li {
		list-style-type: square;
	}

	h1 {
		border-bottom: 1px solid var(--tap-bg-primary);
		padding-bottom: 10px;
		margin-bottom: 30px;
		font-weight: 600;
		font-style: normal;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		border-bottom: 1px solid #a7a7a7;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	.h1-no-pagebreak {
		border-bottom: 1px solid #a7a7a7;
		padding-bottom: 10px;
		margin-bottom: 30px;
		font-weight: 600;
		font-style: normal;
		color: var(--tap-soa-heading);
		font-family: var(--tap-font);
		font-size: 22px;
		letter-spacing: 1.12px;
		display: block;
	}

	span.custom-title {
		font-weight: normal;
		font-size: 22px !important;
		color: #353535;
		display: block;
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 1px solid var(--tap-primary-color) !important;
	}

	// Table Styles
	.tblNoBorder {
		td,
		th {
			border: none !important;
			padding-left: 0px;
			// background-color: transparent !important;
			border-style: hidden !important;
		}
	}
	.tblTopBorderOnly {
		tr {
			td,
			th {
				padding-left: 0px;
			}
			border-top: 1px solid #ddd !important;
			border-left: 1px hidden #ddd !important;
			border-right: 1px hidden #ddd !important;
			border-bottom: 1px hidden #ddd !important;
		}
	}
	.no-border-left-right {
		border: none;
		tr {
			td,
			th {
				&:first-child {
					border-left: 0px !important;
				}
				&:last-child {
					border-right: 0px !important;
				}
			}
		}
	}
	.hide-table-border {
		td,
		th {
			border: none !important;
			padding-left: 0px;
			border-style: hidden !important;
		}
	}
	.hr-line-only {
		border: none;
		tr {
			td,
			th {
				border-left: 0px;
				border-right: 0px;
			}
		}
	}
	.no-border-hr-lines {
		border: none;
		tr {
			td,
			th {
				border-left: 0px;
				border-right: 0px;
				border-top: 0px;
			}
		}
		tr:last-child {
			td {
				border-bottom: 0px;
			}
		}
	}
	.no-outer-border {
		border: none;
		tr {
			td,
			th {
				border-right: 0px;
				border-top: 0px;
				border-left-color: #eeefee !important;
				border-bottom-color: #eeefee !important;
				&:first-child {
					border-left-color: transparent !important;
				}
			}
		}
	}
	table.total {
		tbody tr {
			td {
				background-color: #f2f3f2;
				font-weight: normal !important;
				border: none !important;
			}
			.strong {
				font-weight: normal !important;
			}
		}
	}
	table,
	th,
	td {
		border: 1px solid #dddddd;
	}

	table {
		table-layout: fixed;
		th {
			background-color: #ececec;
			word-break: break-word !important;
			padding: 4px;
			p {
				display: initial;
			}
		}
		td {
			word-break: break-word !important;
			padding: 4px;
			p {
				display: initial;
			}
		}
		&.header-fill {
			background-color: #f2f3f2;
		}
		thead,
		tbody {
			tr.header-fill {
				background-color: #f2f3f2 !important;
			}
		}
	}

	table.mtag-hide-tbody {
		tbody {
			tr:not(:first-child) {
				display: none;
			}
			.no-data-display {
				td {
					text-align: center;
				}
			}
		}
	}

	table.mtag-hide-table {
		display: none;
	}

	table.fullWidth {
		width: 643px !important;
	}

	table {
		margin-top: -1px;
		margin-bottom: -1px;
	}

	// Table Cell Styles
	.dynamicDependants-hide {
		thead {
			th {
				&:last-child {
					display: none;
				}
			}
		}
		tbody {
			td {
				&:last-child {
					display: none;
				}
			}
		}
	}

	.cellNoBorder {
		border: none !important;
	}

	.cellLeftIndent {
		padding-left: 15px;
	}

	// Signature
	.signature-wrapper {
		page-break-inside: avoid;
	}

	.signature-container {
		width: 100%;
		display: block;
		margin-bottom: 0px;
		span {
			border-bottom: 1px solid #212529;
		}
		strong {
			color: #212529;
		}
		span,
		strong {
			display: inline-block;
			width: 26%;
			vertical-align: bottom;
		}

		span:not(:last-child),
		strong:not(:last-child) {
			margin-right: 10%;
		}
	}

	.signature-wrapper:not(:last-child) {
		margin-bottom: 20px;
		.signature-container {
			span,
			strong {
				padding-bottom: 2px;
			}
		}
	}

	// Checkboxes
	.custom-control-input:checked ~ .custom-control-label::before {
		border-color: var(--tap-primary-color);
		background-color: var(--tap-primary-color);
	}

	.custom-checkbox .custom-control-label::before {
		border-radius: 2px;
	}

	.custom-control-label::before,
	.custom-control-label::after {
		top: 0;
	}

	.custom-control-label::after {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-size: 75%;
		background-position: center top;
	}

	// Other styles
	.tap-text-primary-pdf {
		color: var(--tap-primary-color);
	}

	.hide-from-pdf {
		display: none;
	}

	.document-title {
		display: block;
	}

	.no-data-placeholder {
		color: #999;
		pointer-events: none;
	}

	.half-col-pdf {
		width: 50%;
		display: inline-block;
	}

	.custom-notes {
		border: 1px solid #ccc;
		display: block;
		min-height: 82px;
		width: 100%;
		padding: 5px;
		p {
			margin-top: 10px;
			page-break-inside: avoid;
			page-break-before: auto;
			// word-wrap: normal;
		}
	}

	.countryOfOrigin {
		&-hide {
			display: none;
		}
	}

	.wrap-parenthesis:before {
		content: "(";
	}

	.wrap-parenthesis:after {
		content: ")";
	}

	/*
	* LOAT > SOA
	* Download PDF Styles
	*/
	.soa-pdf-file {
		display: block;
		h1 {
			page-break-before: always;
			page-break-inside: avoid;
			font-weight: 600;
			color: var(--tap-soa-heading);
		}
		h2 {
			color: var(--tap-soa-heading);
		}
		p {
			color: #212529;
		}
		table {
			width: 643px !important;
		}
	}

	/*
	* LOAT V2 > SOA
	* Download PDF Styles
	*/
	.soa-pdf-file-v2 {
		h1 {
			page-break-before: always;
			page-break-inside: avoid;
			font-weight: 600;
			// color: var(--tap-soa-heading);
		}
		h2 {
			color: var(--tap-soa-heading);
		}
		p {
			color: #212529;
		}
		table {
			width: 643px !important;
		}
		#SOS_SERVICES_INCLUDED_LIST {
			margin-bottom: 20px;
			ul {
				margin: 0px 0px 10px 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include green-check-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					border-left: 1px solid white;
					border-right: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid rgba(167, 167, 167, 0.3);
					padding: 0px 20px 12px 50px;
					margin: 15px 0px 0px 0px;
				}
			}
		}
		#SOS_SERVICES_EXCLUDED_LIST {
			margin-bottom: 20px;
			p {
				padding: 0px 0px 15px 50px;
				border-left: 1px solid white;
				border-right: 1px solid white;
				border-top: 1px solid white;
				border-bottom: 1px solid rgba(167, 167, 167, 0.3);
				margin-bottom: 20px;
			}
			ul {
				margin: 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include red-cross-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					padding: 0px 20px 12px 50px;
				}
			}
		}
		#SOS_OTHER_ADVISE_LIST {
			margin-bottom: 20px;
			p {
				padding: 0px 0px 15px 50px;
				border-left: 1px solid white;
				border-right: 1px solid white;
				border-top: 1px solid white;
				border-bottom: 1px solid rgba(167, 167, 167, 0.3);
				margin-bottom: 20px;
				page-break-inside: avoid;
			}
			ul {
				margin: 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include green-check-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					padding: 0px 20px 12px 50px;
				}
			}
		}
	}

	/*
	* LOAT > Authority To Proceed
	* Download PDF Styles
	*/
	.atp-pdf-file {
		font-family: Arial, Helvetica, sans-serif;
		color: #343434;
		font-size: 10pt;
		p {
			font-family: Arial, Helvetica, sans-serif;
			color: #343434;
		}
		.heading {
			font-size: 15pt;
			color: #434343;
			font-weight: 700;
			font-style: normal;
		}
		table {
			font-size: 8pt;
			border: solid #d9d9d9 1px;
			tbody {
				td {
					text-align: center;
					font-size: 11px;
				}
			}
			td,
			th {
				border: solid #d9d9d9 1px;
				vertical-align: top;
				padding: 5px;
				overflow-wrap: break-word;
				font-family: Arial, Helvetica, sans-serif;
				color: #343434;
			}
		}
		.signature-container {
			font-family: Arial, Helvetica, sans-serif;
			color: #343434;
		}
		.atp-wordings {
			white-space: pre-wrap;
		}
	}

	/*
	* LOAT > Final Structure
	* Download PDF Styles
	*/
	.finalstructure-pdf-file {
		p {
			letter-spacing: 0.81px;
		}
		.lifeAssuredName {
			font-size: 16px;
			letter-spacing: 0.61px !important;
		}
	}

	/*
	* LOAT/MOAT > Disclosure Document
	* Download PDF Styles
	*/
	.disclosure-pdf-file {
		p {
			letter-spacing: 0.81px;
		}
	}

	/*
	* LOAT/MOAT > Scope of Service
	* Download PDF Styles
	*/
	.sos-pdf-file {
		position: relative;
		p,
		span {
			letter-spacing: 0.81px;
			white-space: pre-line;
		}
		h1 {
			margin-bottom: 10px;
		}
		> p {
			padding-bottom: 15px;
		}
		ul {
			padding: 0;
			margin-bottom: 25px;
			display: table;
			border-collapse: collapse;
			width: 80%;
		}
		ul > li {
			display: table-row;

			width: 80%;
			list-style-type: none;
			text-align: left;
			align-items: center;

			border-bottom: 1px solid rgba(167, 167, 167, 0.3);
			> * {
				display: table-cell;
			}
			svg {
				margin-right: 10px;
			}
			.icon {
				width: 50px;
			}
			div:not(.icon) {
				padding: 10px 0;
			}
			div {
				p {
					margin-bottom: 0;
					padding-bottom: 0;
				}
				span {
					margin-left: auto;
					font-size: 13px;
				}
			}
			span.sos-cbox-notes,
			span.sos-other-advice-notes {
				margin-top: 10px;
				display: block;
			}
		}
	}

	/*
	* LOAT > Declaration
	*/
	.declaration-pdf-file {
		table {
			page-break-before: always;
		}
	}

	/*
	* MOAT > Declaration
	*/
	.moat-declaration-pdf {
		table {
			page-break-before: always;
		}
	}

	/*
	* MOAT > Client Fact Find
	* Download PDF Styles
	*/
	.moat-cff-pdf {
		table {
			margin-top: 1px;
			max-width: 643px !important;
		}
		p {
			letter-spacing: 0.81px !important;
		}
		ul.sos-cbox {
			margin-top: 0px !important;
			padding: 0;
			margin-bottom: 25px;
			display: table;
			border-collapse: collapse;
			width: 80%;
		}
		ul.sos-cbox > li {
			display: table-row;
			width: 80%;
			list-style-type: none;
			text-align: left;
			align-items: center;
			border-bottom: 1px solid rgba(167, 167, 167, 0.3);
			> * {
				display: table-cell;
			}
			svg {
				margin-right: 10px;
			}
			.icon {
				width: 50px;
			}
			div:not(.icon) {
				padding: 10px 0;
			}
			div {
				p {
					margin-bottom: 0;
				}
				span {
					margin-left: auto;
				}
			}
		}
		span.sos-cbox-notes,
		span.sos-other-advice-notes {
			margin-top: 10px;
			display: block;
		}
		.expenses-tables {
			table.cff-pdf {
				page-break-inside: avoid;
				td,
				th {
					padding-left: 6px !important;
					padding-right: 6px !important;
				}
			}
		}
	}

	/*
	* MOAT > SOA
	* Download PDF Styles
	*/
	.moat-soa-pdf {
		h1 {
			page-break-before: always;
			page-break-inside: avoid;
			font-weight: 600;
		}
		h2 {
			color: var(--tap-soa-heading);
		}
		label.tap-text-primary {
			font-weight: 600;
			display: block;
			width: unset;
		}
		#MOAT_SOS_NOTES_TEXT {
			font-size: 14px;
		}
		#SOS_INCLUDED_LIST {
			margin-bottom: 20px;
			ul {
				margin: 0px 0px 10px 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include green-check-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					border-left: 1px solid white;
					border-right: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid rgba(167, 167, 167, 0.3);
					padding: 0px 20px 12px 50px;
					margin: 15px 0px 0px 0px;
				}
			}
			.sos-other-description {
				margin-top: 10px;
				display: block;
				width: 100%;
			}
		}
		#SOS_EXCLUDED_LIST {
			margin-bottom: 20px;
			ul {
				margin: 0px 0px 10px 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include red-cross-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					border-left: 1px solid white;
					border-right: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid rgba(167, 167, 167, 0.3);
					padding: 0px 20px 12px 50px;
					margin: 15px 0px 0px 0px;
				}
			}
		}
		#SOS_EXCLUDED_LIST_NO_ICON {
			margin-bottom: 20px;
			ul {
				margin: 0px 0px 10px 0px;
				padding: 0px;
				li {
					display: block;
					width: 100%;
					border-left: 1px solid white;
					border-right: 1px solid white;
					border-top: 1px solid white;
					border-bottom: 1px solid rgba(167, 167, 167, 0.3);
					padding: 10px 20px;
				}
			}
		}
		#SOS_OTHER_ADVISE_LIST {
			margin-bottom: 20px;
			p {
				padding: 0px 0px 15px 50px;
				border-left: 1px solid white;
				border-right: 1px solid white;
				border-top: 1px solid white;
				border-bottom: 1px solid rgba(167, 167, 167, 0.3);
				margin-bottom: 20px;
			}
			ul {
				margin: 0px;
				padding: 0px;
				li {
					&:before {
						content: "";
						@include green-check-svg-icon;
						width: 20px;
						height: 20px;
						position: absolute;
						margin: 2px 0px 1px -37px;
					}
					display: block;
					width: 100%;
					padding: 0px 20px 12px 50px;
				}
			}
		}
	}

	/*
	* MOAT > Review App
	* Download PDF Styles
	*/
	.moat-review-app-pdf {
		table {
			page-break-inside: avoid;
		}
	}
	.security-lvr-properties {
		ul {
			li {
				margin-left: 20px;
				list-style: circle;
			}
		}
	}

	ol {
		margin-left: 5px;
	}

	/* KOAT
	* KOAT Settings > SOS
	*/
	.kiwisaver-sos {
		position: relative;
		display: block;
		width: 100%;

		&__question {
			font-size: 22px;
			margin-bottom: 36px;
		}

		&__container {
			position: relative;
			display: table;
			margin-left: -15px;
			margin-right: -15px;
			text-align: center;
			border-spacing: 15px 0px;

			&__item {
				display: table-cell;
				margin-bottom: 20px;
				width: 50%;
				min-height: 398px;

				border: 1px solid rgba(33, 38, 41, 0.2);
				border-radius: 10px;
				padding: 20px 20px;
				background-color: #ffffff;
				cursor: pointer;

				&--selected {
					border: none;
					background-color: #ffffff !important;
					color: var(--tap-primary-color) !important;
					cursor: default;
				}

				&--info {
					justify-content: flex-start;
					margin-bottom: 20px;
					height: auto;
					min-height: 398px;
					border: none;
					background-color: #ffffff !important;
					cursor: default;
				}

				i {
					font-size: 70px;
				}

				svg {
					margin-bottom: 20px;
				}

				.caption {
					font-size: 19px;
					font-weight: normal !important;
					margin-bottom: 20px;
				}

				.info {
					line-height: 22px;
					margin-bottom: 20px;
					padding-left: 20px;
					padding-right: 20px;
				}

				&--active {
					color: #ffffff;
					background-color: var(--tap-primary-color);
					svg {
						fill: #ffffff;
					}
				}
			}

			&__or {
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin: auto;
				margin-top: -30px;
				margin-left: calc(50% - 30px);
				width: 60px;
				height: 60px;
				background: #ffffff;
				border: 1px solid #000000;
				font-weight: 700;
				font-size: 20px;
				border-radius: 50rem;
				display: table;
				text-align: center;
				> span {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}
	/*
	* KOAT > Advice Summary
	* Download PDF Styles
	*/
	.koat-as-pdf {
		display: block;
		h1 {
			page-break-before: always;
			page-break-inside: avoid;
			font-weight: 600;
		}
		h2 {
			color: var(--tap-soa-heading);
		}
	}

	/*
	* LOAT > FACT FIND
	* Download PDF Styles
	*/
	.loatcff-pdf {
		&.loatcff-body-pdf,
		&.loatcff-nextsteps-pdf,
		&.default-generic-cover {
			width: 100%;
			max-width: 630px;
			margin-left: 84px;
		}
		&.default-generic-cover {
			.loatcff-generic-title {
				margin-top: 200px;
			}
			.loatcff-generic-bottom {
				position: absolute;
				top: 760px;
				width: 100%;
			}
		}
		&.custom-generic-cover {
			padding-left: 85px;
			width: 815px;
			// height: 1150px; // w/o page header
			height: 1054px; // w/ page header
			margin: 0px;
			overflow: hidden;
			.loatcff-generic-cover-details {
				// padding-left: 84px;
				// padding-right: 84px;
			}
			.loatcff-generic-img {
				display: none;
			}
			.loatcff-generic-cover-bg {
				height: 45%;
				width: 100%;
				display: block;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
		}
		.loatcff-generic-img {
			img {
				max-height: 130px;
			}
		}
		.loatcff-income-source,
		.loatcff-rental-income:last-child,
		.loatcff-other-income:last-child,
		table.loatcff-property,
		table.loatcff-assets,
		table.loatcff-kiwisaver,
		table.loatcff-mortgages,
		table.loatcff-liabilities,
		table.loatcff-gp-details,
		table.loatcff-medical-history,
		table.loatcff-family-history:not(:last-child) {
			margin-bottom: 40px !important;
		}
		table.loatcff-medical-history {
			border: none;
			tr:last-child {
				td.loatcff-medical-history-notes {
					border-bottom: 0px;
				}
			}
			tr {
				td {
					display: inline-block;
					&.loatcff-medical-history-health-issue,
					&.loatcff-medical-history-first-symptoms,
					&.loatcff-medical-history-last-symptoms,
					&.loatcff-medical-history-current-terms {
						width: 25%;
						border-top: 0px;
						border-right: 0px;
					}
					&.loatcff-medical-history-health-issue:not(first-child) {
						border-left: 0px;
					}
					&.loatcff-medical-history-notes {
						width: 100%;
						border-top: 0px;
						border-left: 0px;
						border-right: 0px;
						&:before {
							content: "Notes: ";
						}
					}
				}
			}
		}
		table.loatcff-family-history {
			border: none;
			tr:last-child {
				td.loatcff-family-history-notes {
					border-bottom: 0px;
				}
			}
			tr {
				td {
					display: inline-block;
					&.loatcff-family-history-health-issue,
					&.loatcff-family-history-relationship,
					&.loatcff-family-history-age-diagnosis,
					&.loatcff-family-history-current-terms {
						width: 25%;
						border-top: 0px;
						border-right: 0px;
					}
					&.loatcff-family-history-health-issue:not(first-child) {
						border-left: 0px;
					}
					&.loatcff-family-history-notes {
						width: 100%;
						border-top: 0px;
						border-left: 0px;
						border-right: 0px;
						&:before {
							content: "Notes: ";
						}
					}
				}
			}
		}
		.loatcff-annual-taxable {
			width: 815px !important;
			position: relative !important;
			left: -84px !important;
			max-width: unset !important;
			margin-top: 30px;
			border: none;
			background-color: var(--tap-bg-primary);
			td,
			th {
				color: var(--tap-h1-color);
				border: none;
				padding: 20px 6px !important;
			}
			td:first-child {
				padding-left: 84px !important;
			}
		}
		.sos-pdf-file {
			p:last-child {
				padding-bottom: 0px !important;
			}
		}
	}

	/*
	* CAR > *
	* Download PDF Styles
	*/
	.car-pdf-template {
		p,
		div,
		span,
		td {
			font-family: "Verdana" !important;
			letter-spacing: normal !important;
		}
		&.default-generic-cover {
			width: 100%;
			max-width: 630px;
			margin-left: 84px;
		}
		&.default-generic-cover {
			.car-policy-generic-title {
				margin-top: 200px;
			}
			.car-policy-generic-bottom {
				position: absolute;
				top: 760px;
				width: 100%;
			}
		}
		&.custom-generic-cover {
			padding-left: 85px;
			width: 815px;
			// height: 1150px; // w/o page header
			height: 1054px; // w/ page header
			margin: 0px;
			overflow: hidden;
			.car-policy-generic-cover-details {
				// padding-left: 84px;
				// padding-right: 84px;
			}
			.car-policy-generic-img {
				display: none;
			}
			.car-policy-generic-cover-bg {
				height: 45%;
				width: 100%;
				display: block;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
		}

		.car-template-generic-img {
			img {
				max-height: 130px;
			}
		}
	}
}
